import { RegionItem } from './region';

export interface UploadExcelResponse {
  status: string;
  code: number;
  message: string;
  object: {
    title: string;
    initial_rows_count: number;
    uid: string;
    updated_at: string;
    created_at: string;
  };
}

export interface OrderExcelResponse {
  status: string;
  code: number;
  message: string;
  object: {
    items: ImportItem[];
    page: number;
    total: number;
    pages: number;
    perPage: number;
    initial_rows_count: number;
    rows_count: number;
    invalid_rows_count: number;
    valid_rows_count: number;
  };
}

export interface ImportItem {
  uid: string;
  validation_status: 'INVALID' | 'VALID';
  is_fragile: number;
  products_value: number;
  platform_code: string;
  custom_id: string | null;
  product_title: string;
  product_price: string;
  product_quantity: string;
  pick_up_type: string | null;
  payment_type: string;
  shipment_fee_type: string;
  size: string;
  full_name: string;
  phone: string;
  secondary_phone: string;
  email: string | null;
  address_text: string;
  region: RegionItem;
  city: string;
  description: string;
  errors: string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  validity: string;
}

export interface DeleteOrderParams {
  uid: string;
  key: string;
}

export interface MoveOrderParams {
  uid: string;
  data: {
    pick_up_type: 'PICK_UP' | 'DROP_OFF';
    saved_pick_up_address_uid?: string;
  };
}

export enum ButtonFilter {
  All = 'All',
  VALID = 'Valid',
  INVALID = 'Invalid',
}

export interface OrderExcelRowResponse {
  status: string; // "success" or other status strings
  code: number; // HTTP status code, e.g., 200
  message: string; // Message describing the outcome of the operation
  object: OrderExcelRow; // The actual order row object
}

export interface OrderExcelRow {
  uid: string;
  validation_status: 'VALID' | 'INVALID';
  is_fragile: boolean;
  custom_id: string | null;
  product_title: string;
  product_price: string;
  product_quantity: string;
  pick_up_type: 'PICK_UP' | 'DROP_OFF';
  payment_type: 'COLLECT_ON_DELIVERY' | 'PREPAID';
  shipment_fee_type: 'BY_CUSTOMER' | 'BY_MERCHANT';
  size: 'S' | 'M' | 'L';
  full_name: string;
  phone: string;
  secondary_phone?: string | null;
  email?: string | null;
  address_text: string;
  region: RegionItem;
  city: string;
  description: string | null;
  errors: Record<string, string> | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

export interface OrderExcelRowRequestUpdate {
  custom_id: string;
  product_title: string;
  product_price: string;
  product_quantity: string;
  payment_type: 'COLLECT_ON_DELIVERY' | 'PREPAID';
  shipment_fee_type: 'BY_CUSTOMER' | 'BY_MERCHANT';
  full_name: string;
  phone: string;
  secondary_phone?: string;
  size: 'S' | 'M' | 'L';
  region: string;
  city: string;
  address_text: string;
  description: string;
  is_fragile: boolean;
}
//RESPONSE FOR UPDATE:
interface ResponseStatus {
  status: string; // e.g., "success"
  code: number; // e.g., 200
  message: string; // e.g., "Successfully done."
}

interface Errors {
  [key: string]: string[]; // Represents errors as a mapping of field names to an array of error messages
}

interface BeforeUpdate {
  uid: string;
  validation_status: 'VALID' | 'INVALID';
  is_fragile: number; // Assuming 0 or 1
  custom_id: string | null; // Adjusted to allow null
  product_title: string;
  product_price: string; // Assuming prices are represented as strings (e.g., "2500")
  product_quantity: string; // Assuming quantities are represented as strings (e.g., "5")
  pick_up_type: 'PICK_UP' | 'DROP_OFF' | null; // Adjusted to allow null
  payment_type: 'COLLECT_ON_DELIVERY' | 'PREPAID';
  shipment_fee_type: 'BY_CUSTOMER' | 'BY_MERCHANT';
  size: 'S' | 'M' | 'L';
  full_name: string;
  phone: string;
  secondary_phone: string | null; // Adjusted to allow null
  email: string | null; // Adjusted to allow null
  address_text: string;
  region: string;
  city: string;
  description: string | null; // Adjusted to allow null
  errors: Errors | null; // Adjusted to allow null
  created_at: string | null; // Adjusted to allow null
  updated_at: string | null; // Adjusted to allow null
  deleted_at: string | null; // Adjusted to allow null
}

export interface OrderResponseExcelRowUpdate {
  status: ResponseStatus; // The overall response status
  object: {
    uid: string;
    validation_status: 'VALID' | 'INVALID';
    is_fragile: number; // Assuming 0 or 1
    custom_id: string | null; // Adjusted to allow null
    product_title: string;
    product_price: string; // Assuming prices are represented as strings (e.g., "2500")
    product_quantity: string; // Assuming quantities are represented as strings (e.g., "5")
    pick_up_type: 'PICK_UP' | 'DROP_OFF'; // Adjusted to allow null
    payment_type: 'COLLECT_ON_DELIVERY' | 'PREPAID';
    shipment_fee_type: 'BY_CUSTOMER' | 'BY_MERCHANT';
    size: 'S' | 'M' | 'L';
    full_name: string;
    phone: string;
    secondary_phone: string | null; // Adjusted to allow null
    email: string | null; // Adjusted to allow null
    address_text: string;
    region: string;
    city: string;
    description: string | null; // Adjusted to allow null
    errors: Errors | null; // Adjusted to allow null
    created_at: string | null; // Adjusted to allow null
    updated_at: string | null; // Adjusted to allow null
    deleted_at: string | null; // Adjusted to allow null
    before_update: BeforeUpdate; // Include the before_update object
  };
}
