export const countries = [
  {
    name: 'Afghanistan',
    code: 'AF',
    emoji: '🇦🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/af.svg',
    dialCode: '93',
    slug: 'afghanistan',
  },
  {
    name: 'Albania',
    code: 'AL',
    emoji: '🇦🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/al.svg',
    dialCode: '355',
    slug: 'albania',
  },
  {
    name: 'Andorra',
    code: 'AD',
    emoji: '🇦🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ad.svg',
    dialCode: '376',
    slug: 'andorra',
  },
  {
    name: 'Angola',
    code: 'AO',
    emoji: '🇦🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ao.svg',
    dialCode: '244',
    slug: 'angola',
  },
  {
    name: 'Anguilla',
    code: 'AI',
    emoji: '🇦🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ai.svg',
    dialCode: '1264',
    slug: 'anguilla',
  },
  {
    name: 'Antarctica',
    code: 'AQ',
    emoji: '🇦🇶',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/aq.svg',
    dialCode: '672',
    slug: 'antarctica',
  },
  {
    name: 'Antigua & Barbuda',
    code: 'AG',
    emoji: '🇦🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ag.svg',
    dialCode: '1268',
    slug: 'antigua-and-barbuda',
  },
  {
    name: 'Argentina',
    code: 'AR',
    emoji: '🇦🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ar.svg',
    dialCode: '54',
    slug: 'argentina',
  },
  {
    name: 'Armenia',
    code: 'AM',
    emoji: '🇦🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/am.svg',
    dialCode: '374',
    slug: 'armenia',
  },
  {
    name: 'Aruba',
    code: 'AW',
    emoji: '🇦🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/aw.svg',
    dialCode: '297',
    slug: 'aruba',
  },
  {
    name: 'Australia',
    code: 'AU',
    emoji: '🇦🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/au.svg',
    dialCode: '61',
    slug: 'australia',
  },
  {
    name: 'Austria',
    code: 'AT',
    emoji: '🇦🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/at.svg',
    dialCode: '43',
    slug: 'austria',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    emoji: '🇦🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/az.svg',
    dialCode: '994',
    slug: 'azerbaijan',
  },
  {
    name: 'Bahamas',
    code: 'BS',
    emoji: '🇧🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bs.svg',
    dialCode: '1242',
    slug: 'bahamas',
  },
  {
    name: 'Bahrain',
    code: 'BH',
    emoji: '🇧🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bh.svg',
    dialCode: '973',
    slug: 'bahrain',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    emoji: '🇧🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bd.svg',
    dialCode: '880',
    slug: 'bangladesh',
  },
  {
    name: 'Barbados',
    code: 'BB',
    emoji: '🇧🇧',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bb.svg',
    dialCode: '1246',
    slug: 'barbados',
  },
  {
    name: 'Belarus',
    code: 'BY',
    emoji: '🇧🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/by.svg',
    dialCode: '375',
    slug: 'belarus',
  },
  {
    name: 'Belgium',
    code: 'BE',
    emoji: '🇧🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/be.svg',
    dialCode: '32',
    slug: 'belgium',
  },
  {
    name: 'Belize',
    code: 'BZ',
    emoji: '🇧🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bz.svg',
    dialCode: '501',
    slug: 'belize',
  },
  {
    name: 'Benin',
    code: 'BJ',
    emoji: '🇧🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bj.svg',
    dialCode: '229',
    slug: 'benin',
  },
  {
    name: 'Bermuda',
    code: 'BM',
    emoji: '🇧🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bm.svg',
    dialCode: '1441',
    slug: 'bermuda',
  },
  {
    name: 'Bhutan',
    code: 'BT',
    emoji: '🇧🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bt.svg',
    dialCode: '975',
    slug: 'bhutan',
  },
  {
    name: 'Bolivia',
    code: 'BO',
    emoji: '🇧🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bo.svg',
    dialCode: '591',
    slug: 'bolivia',
  },
  {
    name: 'Bosnia & Herzegovina',
    code: 'BA',
    emoji: '🇧🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ba.svg',
    dialCode: '387',
    slug: 'bosnia-and-herzegovina',
  },
  {
    name: 'Botswana',
    code: 'BW',
    emoji: '🇧🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bw.svg',
    dialCode: '267',
    slug: 'botswana',
  },
  {
    name: 'Brazil',
    code: 'BR',
    emoji: '🇧🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/br.svg',
    dialCode: '55',
    slug: 'brazil',
  },
  {
    name: 'Brunei',
    code: 'BN',
    emoji: '🇧🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bn.svg',
    dialCode: '673',
    slug: 'brunei',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    emoji: '🇧🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bg.svg',
    dialCode: '359',
    slug: 'bulgaria',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    emoji: '🇧🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bf.svg',
    dialCode: '226',
    slug: 'burkina-faso',
  },
  {
    name: 'Burundi',
    code: 'BI',
    emoji: '🇧🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bi.svg',
    dialCode: '257',
    slug: 'burundi',
  },
  {
    name: 'Cabo Verde',
    code: 'CV',
    emoji: '🇨🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cv.svg',
    dialCode: '238',
    slug: 'cabo-verde',
  },
  {
    name: 'Cambodia',
    code: 'KH',
    emoji: '🇰🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kh.svg',
    dialCode: '855',
    slug: 'cambodia',
  },
  {
    name: 'Cameroon',
    code: 'CM',
    emoji: '🇨🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cm.svg',
    dialCode: '237',
    slug: 'cameroon',
  },
  {
    name: 'Canada',
    code: 'CA',
    emoji: '🇨🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ca.svg',
    dialCode: '1',
    slug: 'canada',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    emoji: '🇰🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ky.svg',
    dialCode: '1345',
    slug: 'cayman-islands',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    emoji: '🇨🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cf.svg',
    dialCode: '236',
    slug: 'central-african-republic',
  },
  {
    name: 'Chad',
    code: 'TD',
    emoji: '🇹🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/td.svg',
    dialCode: '235',
    slug: 'chad',
  },
  {
    name: 'Chile',
    code: 'CL',
    emoji: '🇨🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cl.svg',
    dialCode: '56',
    slug: 'chile',
  },
  {
    name: 'China',
    code: 'CN',
    emoji: '🇨🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cn.svg',
    dialCode: '86',
    slug: 'china',
  },
  {
    name: 'Colombia',
    code: 'CO',
    emoji: '🇨🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/co.svg',
    dialCode: '57',
    slug: 'colombia',
  },
  {
    name: 'Comoros',
    code: 'KM',
    emoji: '🇰🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/km.svg',
    dialCode: '269',
    slug: 'comoros',
  },
  {
    name: 'Congo - Brazzaville',
    code: 'CG',
    emoji: '🇨🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cg.svg',
    dialCode: '242',
    slug: 'congo-brazzaville',
  },
  {
    name: 'Congo - Kinshasa',
    code: 'CD',
    emoji: '🇨🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cd.svg',
    dialCode: '243',
    slug: 'congo-kinshasa',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    emoji: '🇨🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ck.svg',
    dialCode: '682',
    slug: 'cook-islands',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    emoji: '🇨🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cr.svg',
    dialCode: '506',
    slug: 'costa-rica',
  },
  {
    name: 'Croatia',
    code: 'HR',
    emoji: '🇭🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hr.svg',
    dialCode: '385',
    slug: 'croatia',
  },
  {
    name: 'Cuba',
    code: 'CU',
    emoji: '🇨🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cu.svg',
    dialCode: '53',
    slug: 'cuba',
  },
  {
    name: 'Curaçao',
    code: 'CW',
    emoji: '🇨🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cw.svg',
    dialCode: '599',
    slug: 'curacao',
  },
  {
    name: 'Cyprus',
    code: 'CY',
    emoji: '🇨🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cy.svg',
    dialCode: '357',
    slug: 'cyprus',
  },
  {
    name: 'Czechia',
    code: 'CZ',
    emoji: '🇨🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cz.svg',
    dialCode: '420',
    slug: 'czechia',
  },
  {
    name: 'Côte d’Ivoire',
    code: 'CI',
    emoji: '🇨🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ci.svg',
    dialCode: '225',
    slug: "cote-d'ivoire",
  },
  {
    name: 'Denmark',
    code: 'DK',
    emoji: '🇩🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dk.svg',
    dialCode: '45',
    slug: 'denmark',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    emoji: '🇩🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dj.svg',
    dialCode: '253',
    slug: 'djibouti',
  },
  {
    name: 'Dominica',
    code: 'DM',
    emoji: '🇩🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dm.svg',
    dialCode: '1767',
    slug: 'dominica',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    emoji: '🇩🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/do.svg',
    dialCode: '1 809',
    slug: 'dominican-republic',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    emoji: '🇪🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ec.svg',
    dialCode: '593',
    slug: 'ecuador',
  },
  {
    name: 'Egypt',
    code: 'EG',
    emoji: '🇪🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/eg.svg',
    dialCode: '20',
    slug: 'egypt',
  },
  {
    name: 'El Salvador',
    code: 'SV',
    emoji: '🇸🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sv.svg',
    dialCode: '503',
    slug: 'el-salvador',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    emoji: '🇬🇶',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gq.svg',
    dialCode: '240',
    slug: 'equatorial-guinea',
  },
  {
    name: 'Eritrea',
    code: 'ER',
    emoji: '🇪🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/er.svg',
    dialCode: '291',
    slug: 'eritrea',
  },
  {
    name: 'Estonia',
    code: 'EE',
    emoji: '🇪🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ee.svg',
    dialCode: '372',
    slug: 'estonia',
  },
  {
    name: 'Eswatini',
    code: 'SZ',
    emoji: '🇸🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sz.svg',
    dialCode: '268',
    slug: 'eswatini',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    emoji: '🇪🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/et.svg',
    dialCode: '251',
    slug: 'ethiopia',
  },
  {
    name: 'Falkland Islands',
    code: 'FK',
    emoji: '🇫🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fk.svg',
    dialCode: '500',
    slug: 'falkland-islands',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    emoji: '🇫🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fo.svg',
    dialCode: '298',
    slug: 'faroe-islands',
  },
  {
    name: 'Fiji',
    code: 'FJ',
    emoji: '🇫🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fj.svg',
    dialCode: '679',
    slug: 'fiji',
  },
  {
    name: 'Finland',
    code: 'FI',
    emoji: '🇫🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fi.svg',
    dialCode: '358',
    slug: 'finland',
  },
  {
    name: 'France',
    code: 'FR',
    emoji: '🇫🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fr.svg',
    dialCode: '33',
    slug: 'france',
  },
  {
    name: 'French Guiana',
    code: 'GF',
    emoji: '🇬🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gf.svg',
    dialCode: '594',
    slug: 'french-guiana',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    emoji: '🇵🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pf.svg',
    dialCode: '689',
    slug: 'french-polynesia',
  },
  {
    name: 'Gabon',
    code: 'GA',
    emoji: '🇬🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ga.svg',
    dialCode: '241',
    slug: 'gabon',
  },
  {
    name: 'Gambia',
    code: 'GM',
    emoji: '🇬🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gm.svg',
    dialCode: '220',
    slug: 'gambia',
  },
  {
    name: 'Georgia',
    code: 'GE',
    emoji: '🇬🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ge.svg',
    dialCode: '995',
    slug: 'georgia',
  },
  {
    name: 'Germany',
    code: 'DE',
    emoji: '🇩🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/de.svg',
    dialCode: '49',
    slug: 'germany',
  },
  {
    name: 'Ghana',
    code: 'GH',
    emoji: '🇬🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gh.svg',
    dialCode: '233',
    slug: 'ghana',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    emoji: '🇬🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gi.svg',
    dialCode: '350',
    slug: 'gibraltar',
  },
  {
    name: 'Greece',
    code: 'GR',
    emoji: '🇬🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gr.svg',
    dialCode: '30',
    slug: 'greece',
  },
  {
    name: 'Greenland',
    code: 'GL',
    emoji: '🇬🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gl.svg',
    dialCode: '299',
    slug: 'greenland',
  },
  {
    name: 'Grenada',
    code: 'GD',
    emoji: '🇬🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gd.svg',
    dialCode: '1473',
    slug: 'grenada',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    emoji: '🇬🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gp.svg',
    dialCode: '590',
    slug: 'guadeloupe',
  },
  {
    name: 'Guam',
    code: 'GU',
    emoji: '🇬🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gu.svg',
    dialCode: '1671',
    slug: 'guam',
  },
  {
    name: 'Guatemala',
    code: 'GT',
    emoji: '🇬🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gt.svg',
    dialCode: '502',
    slug: 'guatemala',
  },
  {
    name: 'Guernsey',
    code: 'GG',
    emoji: '🇬🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gg.svg',
    dialCode: '44',
    slug: 'guernsey',
  },
  {
    name: 'Guinea',
    code: 'GN',
    emoji: '🇬🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gn.svg',
    dialCode: '224',
    slug: 'guinea',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    emoji: '🇬🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gw.svg',
    dialCode: '245',
    slug: 'guinea-bissau',
  },
  {
    name: 'Guyana',
    code: 'GY',
    emoji: '🇬🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gy.svg',
    dialCode: '592',
    slug: 'guyana',
  },
  {
    name: 'Haiti',
    code: 'HT',
    emoji: '🇭🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ht.svg',
    dialCode: '509',
    slug: 'haiti',
  },
  {
    name: 'Honduras',
    code: 'HN',
    emoji: '🇭🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hn.svg',
    dialCode: '504',
    slug: 'honduras',
  },
  {
    name: 'Hong Kong SAR China',
    code: 'HK',
    emoji: '🇭🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hk.svg',
    dialCode: '852',
    slug: 'hong-kong-sar-china',
  },
  {
    name: 'Hungary',
    code: 'HU',
    emoji: '🇭🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hu.svg',
    dialCode: '36',
    slug: 'hungary',
  },
  {
    name: 'Iceland',
    code: 'IS',
    emoji: '🇮🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/is.svg',
    dialCode: '354',
    slug: 'iceland',
  },
  {
    name: 'India',
    code: 'IN',
    emoji: '🇮🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/in.svg',
    dialCode: '91',
    slug: 'india',
  },
  {
    name: 'Indonesia',
    code: 'ID',
    emoji: '🇮🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/id.svg',
    dialCode: '62',
    slug: 'indonesia',
  },

  {
    name: 'Ireland',
    code: 'IE',
    emoji: '🇮🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ie.svg',
    dialCode: '353',
    slug: 'ireland',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    emoji: '🇮🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/im.svg',
    dialCode: '44',
    slug: 'isle-of-man',
  },
  {
    name: 'Israel',
    code: 'IL',
    emoji: '🇮🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/il.svg',
    dialCode: '972',
    slug: 'israel',
  },
  {
    name: 'Italy',
    code: 'IT',
    emoji: '🇮🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/it.svg',
    dialCode: '39',
    slug: 'italy',
  },
  {
    name: 'Jamaica',
    code: 'JM',
    emoji: '🇯🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jm.svg',
    dialCode: '1 876',
    slug: 'jamaica',
  },
  {
    name: 'Japan',
    code: 'JP',
    emoji: '🇯🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jp.svg',
    dialCode: '81',
    slug: 'japan',
  },
  {
    name: 'Jersey',
    code: 'JE',
    emoji: '🇯🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/je.svg',
    dialCode: '44',
    slug: 'jersey',
  },
  {
    name: 'Jordan',
    code: 'JO',
    emoji: '🇯🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jo.svg',
    dialCode: '962',
    slug: 'jordan',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    emoji: '🇰🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kz.svg',
    dialCode: '7',
    slug: 'kazakhstan',
  },
  {
    name: 'Kenya',
    code: 'KE',
    emoji: '🇰🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ke.svg',
    dialCode: '254',
    slug: 'kenya',
  },
  {
    name: 'Kiribati',
    code: 'KI',
    emoji: '🇰🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ki.svg',
    dialCode: '686',
    slug: 'kiribati',
  },
  {
    name: 'Kosovo',
    code: 'XK',
    emoji: '🇽🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/xk.svg',
    dialCode: '383',
    slug: 'kosovo',
  },
  {
    name: 'Kuwait',
    code: 'KW',
    emoji: '🇰🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kw.svg',
    dialCode: '965',
    slug: 'kuwait',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    emoji: '🇰🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kg.svg',
    dialCode: '996',
    slug: 'kyrgyzstan',
  },
  {
    name: 'Laos',
    code: 'LA',
    emoji: '🇱🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/la.svg',
    dialCode: '856',
    slug: 'laos',
  },
  {
    name: 'Latvia',
    code: 'LV',
    emoji: '🇱🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lv.svg',
    dialCode: '371',
    slug: 'latvia',
  },
  {
    name: 'Lebanon',
    code: 'LB',
    emoji: '🇱🇧',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lb.svg',
    dialCode: '961',
    slug: 'lebanon',
  },
  {
    name: 'Lesotho',
    code: 'LS',
    emoji: '🇱🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ls.svg',
    dialCode: '266',
    slug: 'lesotho',
  },
  {
    name: 'Liberia',
    code: 'LR',
    emoji: '🇱🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lr.svg',
    dialCode: '231',
    slug: 'liberia',
  },
  {
    name: 'Libya',
    code: 'LY',
    emoji: '🇱🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ly.svg',
    dialCode: '218',
    slug: 'libya',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    emoji: '🇱🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/li.svg',
    dialCode: '423',
    slug: 'liechtenstein',
  },
  {
    name: 'Lithuania',
    code: 'LT',
    emoji: '🇱🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lt.svg',
    dialCode: '370',
    slug: 'lithuania',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    emoji: '🇱🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lu.svg',
    dialCode: '352',
    slug: 'luxembourg',
  },
  {
    name: 'Macao SAR China',
    code: 'MO',
    emoji: '🇲🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mo.svg',
    dialCode: '853',
    slug: 'macao-sar-china',
  },
  {
    name: 'Madagascar',
    code: 'MG',
    emoji: '🇲🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mg.svg',
    dialCode: '261',
    slug: 'madagascar',
  },
  {
    name: 'Malawi',
    code: 'MW',
    emoji: '🇲🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mw.svg',
    dialCode: '265',
    slug: 'malawi',
  },
  {
    name: 'Malaysia',
    code: 'MY',
    emoji: '🇲🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/my.svg',
    dialCode: '60',
    slug: 'malaysia',
  },
  {
    name: 'Maldives',
    code: 'MV',
    emoji: '🇲🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mv.svg',
    dialCode: '960',
    slug: 'maldives',
  },
  {
    name: 'Mali',
    code: 'ML',
    emoji: '🇲🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ml.svg',
    dialCode: '223',
    slug: 'mali',
  },
  {
    name: 'Malta',
    code: 'MT',
    emoji: '🇲🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mt.svg',
    dialCode: '356',
    slug: 'malta',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    emoji: '🇲🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mh.svg',
    dialCode: '692',
    slug: 'marshall-islands',
  },
  {
    name: 'Martinique',
    code: 'MQ',
    emoji: '🇲🇶',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mq.svg',
    dialCode: '596',
    slug: 'martinique',
  },
  {
    name: 'Mauritania',
    code: 'MR',
    emoji: '🇲🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mr.svg',
    dialCode: '222',
    slug: 'mauritania',
  },
  {
    name: 'Mauritius',
    code: 'MU',
    emoji: '🇲🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mu.svg',
    dialCode: '230',
    slug: 'mauritius',
  },
  {
    name: 'Mayotte',
    code: 'YT',
    emoji: '🇾🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/yt.svg',
    dialCode: '262',
    slug: 'mayotte',
  },
  {
    name: 'Mexico',
    code: 'MX',
    emoji: '🇲🇽',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mx.svg',
    dialCode: '52',
    slug: 'mexico',
  },
  {
    name: 'Micronesia',
    code: 'FM',
    emoji: '🇫🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fm.svg',
    dialCode: '691',
    slug: 'micronesia',
  },
  {
    name: 'Moldova',
    code: 'MD',
    emoji: '🇲🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/md.svg',
    dialCode: '373',
    slug: 'moldova',
  },
  {
    name: 'Monaco',
    code: 'MC',
    emoji: '🇲🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mc.svg',
    dialCode: '377',
    slug: 'monaco',
  },
  {
    name: 'Mongolia',
    code: 'MN',
    emoji: '🇲🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mn.svg',
    dialCode: '976',
    slug: 'mongolia',
  },
  {
    name: 'Montenegro',
    code: 'ME',
    emoji: '🇲🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/me.svg',
    dialCode: '382',
    slug: 'montenegro',
  },
  {
    name: 'Montserrat',
    code: 'MS',
    emoji: '🇲🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ms.svg',
    dialCode: '1 664',
    slug: 'montserrat',
  },
  {
    name: 'Morocco',
    code: 'MA',
    emoji: '🇲🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ma.svg',
    dialCode: '212',
    slug: 'morocco',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    emoji: '🇲🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mz.svg',
    dialCode: '258',
    slug: 'mozambique',
  },
  {
    name: 'Myanmar (Burma)',
    code: 'MM',
    emoji: '🇲🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mm.svg',
    dialCode: '95',
    slug: 'myanmar-(burma)',
  },
  {
    name: 'Namibia',
    code: 'NA',
    emoji: '🇳🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/na.svg',
    dialCode: '264',
    slug: 'namibia',
  },
  {
    name: 'Nauru',
    code: 'NR',
    emoji: '🇳🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nr.svg',
    dialCode: '674',
    slug: 'nauru',
  },
  {
    name: 'Nepal',
    code: 'NP',
    emoji: '🇳🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/np.svg',
    dialCode: '977',
    slug: 'nepal',
  },
  {
    name: 'Netherlands',
    code: 'NL',
    emoji: '🇳🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nl.svg',
    dialCode: '31',
    slug: 'netherlands',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    emoji: '🇳🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nc.svg',
    dialCode: '687',
    slug: 'new-caledonia',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    emoji: '🇳🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nz.svg',
    dialCode: '64',
    slug: 'new-zealand',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    emoji: '🇳🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ni.svg',
    dialCode: '505',
    slug: 'nicaragua',
  },
  {
    name: 'Niger',
    code: 'NE',
    emoji: '🇳🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ne.svg',
    dialCode: '227',
    slug: 'niger',
  },
  {
    name: 'Nigeria',
    code: 'NG',
    emoji: '🇳🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ng.svg',
    dialCode: '234',
    slug: 'nigeria',
  },
  {
    name: 'Niue',
    code: 'NU',
    emoji: '🇳🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nu.svg',
    dialCode: '683',
    slug: 'niue',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    emoji: '🇳🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nf.svg',
    dialCode: '672',
    slug: 'norfolk-island',
  },
  {
    name: 'North Korea',
    code: 'KP',
    emoji: '🇰🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kp.svg',
    dialCode: '850',
    slug: 'north-korea',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    emoji: '🇲🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mp.svg',
    dialCode: '1 670',
    slug: 'northern-mariana-islands',
  },
  {
    name: 'Norway',
    code: 'NO',
    emoji: '🇳🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/no.svg',
    dialCode: '47',
    slug: 'norway',
  },
  {
    name: 'Oman',
    code: 'OM',
    emoji: '🇴🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/om.svg',
    dialCode: '968',
    slug: 'oman',
  },
  {
    name: 'Pakistan',
    code: 'PK',
    emoji: '🇵🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pk.svg',
    dialCode: '92',
    slug: 'pakistan',
  },
  {
    name: 'Palau',
    code: 'PW',
    emoji: '🇵🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pw.svg',
    dialCode: '680',
    slug: 'palau',
  },
  {
    name: 'Palestinian Territories',
    code: 'PS',
    emoji: '🇵🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ps.svg',
    dialCode: '970',
    slug: 'palestinian-territories',
  },
  {
    name: 'Panama',
    code: 'PA',
    emoji: '🇵🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pa.svg',
    dialCode: '507',
    slug: 'panama',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    emoji: '🇵🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pg.svg',
    dialCode: '675',
    slug: 'papua-new-guinea',
  },
  {
    name: 'Paraguay',
    code: 'PY',
    emoji: '🇵🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/py.svg',
    dialCode: '595',
    slug: 'paraguay',
  },
  {
    name: 'Peru',
    code: 'PE',
    emoji: '🇵🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pe.svg',
    dialCode: '51',
    slug: 'peru',
  },
  {
    name: 'Philippines',
    code: 'PH',
    emoji: '🇵🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ph.svg',
    dialCode: '63',
    slug: 'philippines',
  },
  {
    name: 'Pitcairn Islands',
    code: 'PN',
    emoji: '🇵🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pn.svg',
    dialCode: '64',
    slug: 'pitcairn-islands',
  },
  {
    name: 'Poland',
    code: 'PL',
    emoji: '🇵🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pl.svg',
    dialCode: '48',
    slug: 'poland',
  },
  {
    name: 'Portugal',
    code: 'PT',
    emoji: '🇵🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pt.svg',
    dialCode: '351',
    slug: 'portugal',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    emoji: '🇵🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pr.svg',
    dialCode: '1 787',
    slug: 'puerto-rico',
  },
  {
    name: 'Qatar',
    code: 'QA',
    emoji: '🇶🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/qa.svg',
    dialCode: '974',
    slug: 'qatar',
  },
  {
    name: 'Réunion',
    code: 'RE',
    emoji: '🇷🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/re.svg',
    dialCode: '262',
    slug: 'reunion',
  },
  {
    name: 'Romania',
    code: 'RO',
    emoji: '🇷🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ro.svg',
    dialCode: '40',
    slug: 'romania',
  },
  {
    name: 'Russia',
    code: 'RU',
    emoji: '🇷🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ru.svg',
    dialCode: '7',
    slug: 'russia',
  },
  {
    name: 'Rwanda',
    code: 'RW',
    emoji: '🇷🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/rw.svg',
    dialCode: '250',
    slug: 'rwanda',
  },
  {
    name: 'Saint Helena',
    code: 'SH',
    emoji: '🇸🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sh.svg',
    dialCode: '290',
    slug: 'saint-helena',
  },
  {
    name: 'Saint Kitts & Nevis',
    code: 'KN',
    emoji: '🇰🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kn.svg',
    dialCode: '1 869',
    slug: 'saint-kitts-and-nevis',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    emoji: '🇱🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lc.svg',
    dialCode: '1 758',
    slug: 'saint-lucia',
  },
  {
    name: 'Saint Pierre & Miquelon',
    code: 'PM',
    emoji: '🇵🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pm.svg',
    dialCode: '508',
    slug: 'saint-pierre-and-miquelon',
  },
  {
    name: 'Samoa',
    code: 'WS',
    emoji: '🇼🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ws.svg',
    dialCode: '685',
    slug: 'samoa',
  },
  {
    name: 'San Marino',
    code: 'SM',
    emoji: '🇸🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sm.svg',
    dialCode: '378',
    slug: 'san-marino',
  },
  {
    name: 'Sao Tome & Principe',
    code: 'ST',
    emoji: '🇸🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/st.svg',
    dialCode: '239',
    slug: 'sao-tome-and-principe',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    emoji: '🇸🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sa.svg',
    dialCode: '966',
    slug: 'saudi-arabia',
  },
  {
    name: 'Senegal',
    code: 'SN',
    emoji: '🇸🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sn.svg',
    dialCode: '221',
    slug: 'senegal',
  },
  {
    name: 'Serbia',
    code: 'RS',
    emoji: '🇷🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/rs.svg',
    dialCode: '381',
    slug: 'serbia',
  },
  {
    name: 'Seychelles',
    code: 'SC',
    emoji: '🇸🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sc.svg',
    dialCode: '248',
    slug: 'seychelles',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    emoji: '🇸🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sl.svg',
    dialCode: '232',
    slug: 'sierra-leone',
  },
  {
    name: 'Singapore',
    code: 'SG',
    emoji: '🇸🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sg.svg',
    dialCode: '65',
    slug: 'singapore',
  },
  {
    name: 'Sint Maarten',
    code: 'SX',
    emoji: '🇸🇽',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sx.svg',
    dialCode: '1 721',
    slug: 'sint-maarten',
  },
  {
    name: 'Slovakia',
    code: 'SK',
    emoji: '🇸🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sk.svg',
    dialCode: '421',
    slug: 'slovakia',
  },
  {
    name: 'Slovenia',
    code: 'SI',
    emoji: '🇸🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/si.svg',
    dialCode: '386',
    slug: 'slovenia',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    emoji: '🇸🇧',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sb.svg',
    dialCode: '677',
    slug: 'solomon-islands',
  },
  {
    name: 'Somalia',
    code: 'SO',
    emoji: '🇸🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/so.svg',
    dialCode: '252',
    slug: 'somalia',
  },
  {
    name: 'South Africa',
    code: 'ZA',
    emoji: '🇿🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/za.svg',
    dialCode: '27',
    slug: 'south-africa',
  },
  {
    name: 'South Korea',
    code: 'KR',
    emoji: '🇰🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kr.svg',
    dialCode: '82',
    slug: 'south-korea',
  },
  {
    name: 'South Sudan',
    code: 'SS',
    emoji: '🇸🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ss.svg',
    dialCode: '211',
    slug: 'south-sudan',
  },
  {
    name: 'Spain',
    code: 'ES',
    emoji: '🇪🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/es.svg',
    dialCode: '34',
    slug: 'spain',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    emoji: '🇱🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lk.svg',
    dialCode: '94',
    slug: 'sri-lanka',
  },
  {
    name: 'Sudan',
    code: 'SD',
    emoji: '🇸🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sd.svg',
    dialCode: '249',
    slug: 'sudan',
  },
  {
    name: 'Suriname',
    code: 'SR',
    emoji: '🇸🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sr.svg',
    dialCode: '597',
    slug: 'suriname',
  },
  {
    name: 'Sweden',
    code: 'SE',
    emoji: '🇸🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/se.svg',
    dialCode: '46',
    slug: 'sweden',
  },
  {
    name: 'Switzerland',
    code: 'CH',
    emoji: '🇨🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ch.svg',
    dialCode: '41',
    slug: 'switzerland',
  },
  {
    name: 'Syria',
    code: 'SY',
    emoji: '🇸🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sy.svg',
    dialCode: '963',
    slug: 'syria',
  },
  {
    name: 'Taiwan',
    code: 'TW',
    emoji: '🇹🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tw.svg',
    dialCode: '886',
    slug: 'taiwan',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    emoji: '🇹🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tj.svg',
    dialCode: '992',
    slug: 'tajikistan',
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    emoji: '🇹🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tz.svg',
    dialCode: '255',
    slug: 'tanzania',
  },
  {
    name: 'Thailand',
    code: 'TH',
    emoji: '🇹🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/th.svg',
    dialCode: '66',
    slug: 'thailand',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    emoji: '🇹🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tl.svg',
    dialCode: '670',
    slug: 'timor-leste',
  },
  {
    name: 'Togo',
    code: 'TG',
    emoji: '🇹🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tg.svg',
    dialCode: '228',
    slug: 'togo',
  },
  {
    name: 'Tokelau',
    code: 'TK',
    emoji: '🇹🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tk.svg',
    dialCode: '690',
    slug: 'tokelau',
  },
  {
    name: 'Tonga',
    code: 'TO',
    emoji: '🇹🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/to.svg',
    dialCode: '676',
    slug: 'tonga',
  },
  {
    name: 'Trinidad & Tobago',
    code: 'TT',
    emoji: '🇹🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tt.svg',
    dialCode: '1 868',
    slug: 'trinidad-and-tobago',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    emoji: '🇹🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tn.svg',
    dialCode: '216',
    slug: 'tunisia',
  },
  {
    name: 'Turkey',
    code: 'TR',
    emoji: '🇹🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tr.svg',
    dialCode: '90',
    slug: 'turkey',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    emoji: '🇹🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tm.svg',
    dialCode: '993',
    slug: 'turkmenistan',
  },
  {
    name: 'Turks & Caicos Islands',
    code: 'TC',
    emoji: '🇹🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tc.svg',
    dialCode: '1 649',
    slug: 'turks-and-caicos-islands',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    emoji: '🇹🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tv.svg',
    dialCode: '688',
    slug: 'tuvalu',
  },
  {
    name: 'Uganda',
    code: 'UG',
    emoji: '🇺🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ug.svg',
    dialCode: '256',
    slug: 'uganda',
  },
  {
    name: 'Ukraine',
    code: 'UA',
    emoji: '🇺🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ua.svg',
    dialCode: '380',
    slug: 'ukraine',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    emoji: '🇦🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ae.svg',
    dialCode: '971',
    slug: 'united-arab-emirates',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    emoji: '🇬🇧',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gb.svg',
    dialCode: '44',
    slug: 'united-kingdom',
  },
  {
    name: 'United States',
    code: 'US',
    emoji: '🇺🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/us.svg',
    dialCode: '1',
    slug: 'united-states',
  },
  {
    name: 'Uruguay',
    code: 'UY',
    emoji: '🇺🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/uy.svg',
    dialCode: '598',
    slug: 'uruguay',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    emoji: '🇺🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/uz.svg',
    dialCode: '998',
    slug: 'uzbekistan',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    emoji: '🇻🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/vu.svg',
    dialCode: '678',
    slug: 'vanuatu',
  },
  {
    name: 'Vatican City',
    code: 'VA',
    emoji: '🇻🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/va.svg',
    dialCode: '379',
    slug: 'vatican-city',
  },
  {
    name: 'Venezuela',
    code: 'VE',
    emoji: '🇻🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ve.svg',
    dialCode: '58',
    slug: 'venezuela',
  },
  {
    name: 'Vietnam',
    code: 'VN',
    emoji: '🇻🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/vn.svg',
    dialCode: '84',
    slug: 'vietnam',
  },
  {
    name: 'Wallis & Futuna',
    code: 'WF',
    emoji: '🇼🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/wf.svg',
    dialCode: '681',
    slug: 'wallis-and-futuna',
  },
  {
    name: 'Iran',
    code: 'IR',
    emoji: '🇮🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ir.svg',
    dialCode: '98',
    slug: 'iran',
  },
  {
    name: 'Iraq',
    code: 'IQ',
    emoji: '🇮🇶',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/iq.svg',
    dialCode: '964',
    slug: 'iraq',
  },
];
