import { Flex, Modal, Typography, Card, Spin, Upload, message, Button } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { BarIcon, Heading } from '@/components';
import { useTranslation } from 'react-i18next';
import Dragger from 'antd/es/upload/Dragger';
import { ALLOW_FILE_CSV } from '@/config';
import { useState } from 'react';
import { useUploadBulkResolveExcel } from '@/hooksApi/useImport';

const { Paragraph, Link, Text } = Typography;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BulkResolveModal = ({ isOpen, onClose }: ModalProps) => {
  const [uploadedFile, setUploadedFile] = useState<RcFile | null>(null);

  const { t } = useTranslation();

  const { mutate: UploadExcel, isLoading: loadingExcel } = useUploadBulkResolveExcel();

  const handleBeforeUpload = (file: RcFile) => {
    const isCSVOrXLSX = ALLOW_FILE_CSV.includes(file.type);
    const isFileSizeValid = file.size <= 1048576;

    if (!isCSVOrXLSX) {
      message.error(t('only_csv_xlsx'));
      return Upload.LIST_IGNORE;
    }

    if (!isFileSizeValid) {
      message.error(t('file_size_limit_exceeded'));
      return Upload.LIST_IGNORE;
    }
    setUploadedFile(file);

    return false;
  };

  const uploadFile = async () => {
    if (uploadedFile) {
      const formData = new FormData();
      formData.append('excel', uploadedFile);

      UploadExcel(formData, {
        onSuccess: (data) => {
          message.success(t('upload_Success'));
        },
      });
    }
  };

  const handleSubmit = () => {
    uploadFile();
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setUploadedFile(null);
  };

  return (
    <Modal
      className='rounded-[20px] bg-white border border-customGrayShade3'
      title={
        <Flex
          className='gap-x-1'
          style={{ borderBottom: '1px solid #E1E1E3', paddingBottom: '19px' }}
        >
          <Text className='font-bold text-lg text-customTunaPrimary leading-[20.88px] uppercase'>
            {t('bulk_resolve')}
          </Text>
        </Flex>
      }
      width={760}
      centered
      footer={null}
      open={isOpen}
      onOk={handleClose}
      onCancel={handleClose}
    >
      <Flex vertical gap='1.5rem'>
        <Heading title={t('import_instructions')} level={4} upperCase />
        <Flex vertical>
          <Paragraph>
            <strong>1. {t('download_template')}:</strong> {t('start_download')}{' '}
            <Link href='/boxy-bulk-order-resolve.csv' target='_blank'>
              {t('csv_template')}
            </Link>
            .
          </Paragraph>
          <Paragraph>
            <strong>2. {t('fill_data')}:</strong> {t('use_first')}.
          </Paragraph>
          <Paragraph>
            <strong>3. {t('import_limit')}:</strong> {t('can_import')}.
          </Paragraph>
          {/* <Card className='bg-customGrayShade1'>
            <Heading title={t('how_to_work')} level={5} />
            <Paragraph>
              {t('importing')} <Link href='#'>{t('csv_guide')}</Link>.
            </Paragraph>
          </Card> */}
        </Flex>

        {loadingExcel ? (
          <Spin />
        ) : (
          <Flex vertical className='mb-6'>
            <Heading title={t('upload_file')} level={5} upperCase />

            <Dragger
              name={t('upload_file')}
              multiple={false}
              maxCount={1}
              beforeUpload={handleBeforeUpload}
              accept='.csv, .xlsx'
            >
              <BarIcon className='my-4' />
              <Flex vertical align='center' justify='center'>
                <Flex gap={1}>
                  <Heading
                    title={t('click_to_upload')}
                    fontWeight='font-bold'
                    fontSize='text-base'
                    extraClassName='underline'
                  />
                  <Heading title={t('drag_drop')} fontWeight='font-bold' fontSize='text-base' />
                </Flex>
                <Heading
                  title={t('maximum_size')}
                  fontSize='text-sm'
                  color='text-customGrayShade5'
                />
              </Flex>
            </Dragger>
          </Flex>
        )}

        <Flex gap='16px' justify='end'>
          <Button size={'large'} onClick={handleClose}>
            <Typography.Text>{t('cancel').toUpperCase()}</Typography.Text>
          </Button>
          <Button size={'large'} onClick={handleSubmit} type='primary' disabled={!uploadedFile}>
            <Typography.Text> {t('submit').toUpperCase()}</Typography.Text>
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default BulkResolveModal;
