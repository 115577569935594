import React from 'react';
import { Radio } from 'antd';

interface CustomRadioButtonProps {
  value: string;
  selectedValue: string;
  label: string;
  className?: string;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({ value, selectedValue, label }) => {
  const isSelected = value === selectedValue;

  return (
    <Radio
      value={value}
      checked={isSelected}
      className={`custom-radio-small uppercase font-bold text-lg leading-[20.88px] h-[54px] rounded-[10px] flex items-center px-4 cursor-pointer  ${
        isSelected
          ? 'text-customTunaPrimary bg-customYellowPrimary border border-solid border-customTunaPrimary'
          : 'text-customGrayShade5 bg-transparent border border-solid border-customGrayShade4'
      } `}
    >
      {label}
    </Radio>
  );
};

export default CustomRadioButton;
