import { Form, message } from 'antd';
import { useEffect } from 'react';
import {
  useCreatePickupAddress,
  useFetchPickupAddressByUID,
  useUpdatePickupAddress,
} from '@/hooksApi/usePickupAddress';
import { attachCountryCodeToPhone, getDialCodeByCountryCode, parsePhoneNumber } from '@/utils';
import { useTranslation } from 'react-i18next';
import { CordsProps } from '@/components/AddNewLocationModal/AddCoordinates';
import { useQueryClient } from '@tanstack/react-query';
import { usePickupAddressStore } from '@/stores';
import { DEFAULT_COUNTRY_CODE } from '@/config';

interface PickupAddressFormProps {
  coordinates: CordsProps | null;
  onClose: () => void;
  setCoordinates: (cords: CordsProps) => void;
  visible: boolean;
}

const usePickupAddressForm = ({
  coordinates,
  setCoordinates,
  visible,
  onClose,
}: PickupAddressFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { pickupAddresses } = usePickupAddressStore();

  const { mutate: createRequestPickupAddress, isLoading: loadingCreatePickup } =
    useCreatePickupAddress();

  const { mutate: updateRequestPickupAddress, isLoading: loadingUpdatePickup } =
    useUpdatePickupAddress();

  const { data: dataSinglePickupAddress, isInitialLoading: loadingSinglePickupAddress } =
    useFetchPickupAddressByUID(pickupAddresses?.uid || '');

  useEffect(() => {
    if (pickupAddresses && visible && dataSinglePickupAddress) {
      const phoneInfo = parsePhoneNumber(dataSinglePickupAddress.object.phone);

      form.setFieldsValue({
        ...pickupAddresses,
        countryCode: phoneInfo.countryCode,
        phone: phoneInfo.phone,
        region_uid: dataSinglePickupAddress?.object?.region?.uid,
        province: dataSinglePickupAddress?.object?.region?.province_code,
      });

      if (pickupAddresses?.lat && pickupAddresses?.lng) {
        setCoordinates({
          latitude: +pickupAddresses.lat,
          longitude: +pickupAddresses.lng,
        });
      }
    } else {
      form.resetFields();
    }
  }, [pickupAddresses, dataSinglePickupAddress, visible]);

  const onFinish = (values: any) => {
    const { countryCode, province, ...rest } = values;
    if (!coordinates) {
      message.error(t('select_location'));
      return;
    }

    const requestPayload = {
      ...rest,
      default: true,
      phone: !pickupAddresses
        ? attachCountryCodeToPhone(values.phone, values.countryCode)
        : values.phone,
      lat: String(coordinates?.latitude),
      lng: String(coordinates?.longitude),
    };

    if (!pickupAddresses) {
      createRequestPickupAddress(requestPayload, {
        onSuccess: () => {
          onClose();
          message.success(t('operation'));
          queryClient.refetchQueries(['pickupAddresses']);
        },
      });
    } else {
      updateRequestPickupAddress(
        { data: requestPayload, uid: pickupAddresses?.uid || '' },
        {
          onSuccess: () => {
            onClose();
            message.success(t('operation'));
            queryClient.refetchQueries(['pickupAddresses']);
          },
        },
      );
    }
  };

  return {
    form,
    onFinish,
    loadingCreatePickup,
    loadingUpdatePickup,
    loadingSinglePickupAddress,
  };
};

export default usePickupAddressForm;
