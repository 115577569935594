import { useQueryClient } from '@tanstack/react-query';
import { useCancelOrder, useDeleteOrderById } from '@/hooksApi/useOrder';
import { message } from 'antd';
import { ErrorMessageResponse, OrderStatusTab } from '@/types';

export const useHandleOrderActions = (uid: string | number, activeTab: OrderStatusTab) => {
  const queryClient = useQueryClient();
  const { mutate: CancelOrder, isLoading: CancelOrderLoading } = useCancelOrder();
  const { mutate: DeleteOrder, isLoading: DeleteOrderLoading } = useDeleteOrderById();

  const handleOrderAction = () => {
    if (activeTab === OrderStatusTab.ReadyToShip) {
      DeleteOrder(String(uid), {
        onSuccess() {
          queryClient.refetchQueries();
        },
      });
    } else if (activeTab === OrderStatusTab.InProgress) {
      CancelOrder(String(uid), {
        onSuccess() {
          queryClient.refetchQueries();
        },
      });
    } else {
      console.log('cancel called');
    }
  };

  return { handleOrderAction, isLoading: CancelOrderLoading || DeleteOrderLoading };
};
