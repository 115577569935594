import React from 'react';
import { Col, Flex, message, Row, Typography } from 'antd';
import { Heading } from '../Heading';
import { CopyIcon, UrlIcon } from '../Icons';
import { Card } from '../Card';
import { useTranslation } from 'react-i18next';
import { StatusTag } from '../Status';
import { getStatusTagData } from '@/utils/getStatus';

const { Text } = Typography;

// Define colors for each status

// Define the Props interface
interface OrderInfoCardProps {
  title: string;
  orderIdLabel: string;
  orderId?: string;
  estimateDeliveryLabel?: string;
  estimateDelivery?: string;
  trackingUrlLabel: string;
  trackingUrl: string;
  trackingAuth?: string;
  status: string; // Ensure the status matches one of the defined statuses
}

// Map status keys to their display text
// const STATUS_TEXTS = {
//   DELIVERED: 'delivered',
//   ON_HOLD: 'on_hold',
//   IN_PROGRESS: 'in_progress',
//   CREATED: 'created',
// };

// Update the component to accept props
const OrderInfoCard: React.FC<OrderInfoCardProps> = ({
  title,
  orderIdLabel,
  orderId,
  estimateDeliveryLabel,
  estimateDelivery,
  trackingUrlLabel,
  trackingUrl,
  trackingAuth,
  status,
}) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    if (trackingAuth) {
      navigator.clipboard.writeText(trackingAuth);
      message.success(t('copy'));
    }
  };

  return (
    <Card>
      <Flex justify='space-between'>
        <Heading
          title={title}
          fontSize='text-lg'
          fontWeight='font-bold'
          extraClassName='uppercase'
        />
        <StatusTag
          text={t(`merchant_order_status.${status}`)}
          color={getStatusTagData(status)?.tagColor || 'processing'}
          iconColor={getStatusTagData(status)?.iconColor || ''}
        />
      </Flex>
      <Row gutter={[24, 16]} className='mt-[17px]'>
        <Col lg={7} md={24} xs={24}>
          <Flex vertical>
            <Text className=' font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {orderIdLabel}
            </Text>
            <Text className=' font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {orderId}
            </Text>
          </Flex>
        </Col>
        <Col lg={7} md={24} xs={24}>
          <Flex vertical>
            <Text className=' font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {estimateDeliveryLabel}
            </Text>
            <Text className=' font-normal text-base leading-[20.48px] mt-1 text-customTunaPrimary'>
              {estimateDelivery}
            </Text>
          </Flex>
        </Col>
        <Col lg={7} md={24} xs={24}>
          <Flex vertical>
            <Text className=' font-normal text-sm leading-[18.2px] text-customGrayShade5'>
              {trackingUrlLabel}
            </Text>
            <Flex gap={11} className='mt-1'>
              <CopyIcon onClick={handleCopy} className='cursor-pointer' />
              <a href={trackingUrl} target='_blank' rel='noopener noreferrer'>
                <UrlIcon />
              </a>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default OrderInfoCard;
