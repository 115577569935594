import { ConfigProvider, Table } from 'antd';
import {
  FINANCE_AMOUNT,
  FINANCE_DATE,
  ORDER_STATUS,
  REMITTANCE_ID,
  THEME_VARIABLE,
} from '@/config';
import { useTranslation } from 'react-i18next';
import '@/styles/style.css';
import { StatusTag } from '../Status';
import { getStatusTagData } from '@/utils/getStatus';
import useRemittanceData from '@/hooks/useRemittanceData';
import { PriceWithUnit } from '../Price';
import { FinanceTab } from '@/types'; // Import FinanceTab enum
import { useState } from 'react';
const RemittanceTable = () => {
  const { t } = useTranslation();

  // Use state to manage the active tab (COD, Balance, Transactions, etc.)
  const [activeTab, setActiveTab] = useState<FinanceTab>(FinanceTab.COD); // Default to COD

  // Fetch remittance data, passing both current page (1) and activeTab
  const { dataRemittance, isLoading } = useRemittanceData(1, activeTab); // Pass the activeTab here

  const columns = [
    {
      title: t('date'),
      dataIndex: FINANCE_DATE,
      key: FINANCE_DATE,
    },
    {
      title: t('remittance_id'),
      dataIndex: REMITTANCE_ID,
      key: REMITTANCE_ID,
    },
    {
      title: t('remittance_amount'),
      dataIndex: FINANCE_AMOUNT,
      key: FINANCE_AMOUNT,
      render: (text: number) => (
        <PriceWithUnit
          color={THEME_VARIABLE.customTunaPrimary}
          value={text}
          subTitle={t('iqd')}
          fontSize='14px'
          fontSizeSub='text-sm'
          fontWeight='font-normal'
          fontWeightSub='font-medium'
        />
      ),
    },
    {
      title: t('status'),
      dataIndex: ORDER_STATUS,
      key: ORDER_STATUS,
      render: (text: string) => {
        return (
          <StatusTag
            text={t(text)}
            color={getStatusTagData(text)?.tagColor || 'processing'}
            iconColor={getStatusTagData(text)?.iconColor || ''}
          />
        );
      },
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerSplitColor: 'transparent',
          },
        },
      }}
    >
      <Table
        bordered={false}
        columns={columns}
        dataSource={dataRemittance?.slice(0, 4)}
        loading={isLoading}
        pagination={false}
        className='custom-table mb-6'
      />
    </ConfigProvider>
  );
};

export default RemittanceTable;
