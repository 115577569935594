import { axiosJSONInstance, axiosMultipartInstance } from '@/config';
import {
  MoveOrderParams,
  OrderExcelResponse,
  OrderExcelRowRequestUpdate,
  OrderExcelRowResponse,
  OrderResponseExcelRowUpdate,
  UploadExcelResponse,
} from '@/types';

// upload order excel
export const uploadOrderExcel = async (data: FormData): Promise<UploadExcelResponse> => {
  const response = await axiosMultipartInstance.post(`/merchants/order-excels/`, data);
  return response.data;
};

// upload bulk resolve excel
export const uploadBulkResolveExcel = async (data: FormData): Promise<UploadExcelResponse> => {
  const response = await axiosMultipartInstance.post(
    `/merchants/orders/bulk-resolve-on-hold`,
    data,
  );
  return response.data;
};

// Fetch order excel by UID
export const fetchOrderExcelByUID = async (
  uid: string,
  page?: number,
  pageSize?: number,
): Promise<OrderExcelResponse> => {
  const response = await axiosJSONInstance.get(
    `merchants/order-excels/${uid}/rows?page=${page || 1}&perpage=${pageSize || 25}`,
  );
  return response.data;
};

// Fetch order Valid excel by UID
export const fetchOrderValidExcelByUID = async (
  uid: string,
  page?: number,
  pageSize?: number,
): Promise<OrderExcelResponse> => {
  const response = await axiosJSONInstance.get(
    `merchants/order-excels/${uid}/rows/valid?page=${page || 1}&perpage=${pageSize || 25}`,
  );
  return response.data;
};

// Fetch order InValid excel by UID
export const fetchOrderInValidExcelByUID = async (
  uid: string,
  page?: number,
  pageSize?: number,
): Promise<OrderExcelResponse> => {
  const response = await axiosJSONInstance.get(
    `merchants/order-excels/${uid}/rows/invalid?page=${page || 1}&perpage=${pageSize || 25}`,
  );
  return response.data;
};

// Delete an order excel by UID
export const deleteOrderExcelByUID = async (uid: string, key: string): Promise<void> => {
  await axiosJSONInstance.delete(`/merchants/order-excels/${uid}/rows/${key}`);
};

// Delete an order excel by UID
export const moveOrderExcelByUID = async (
  uid: string,
  data: MoveOrderParams['data'],
): Promise<void> => {
  const response = await axiosJSONInstance.post(
    `/merchants/order-excels/${uid}/move-to-orders`,
    data,
    {
      timeout: 600000, //10 minutes
    },
  );
  return response.data;
};

// Get an order excel row by UID
export const getOrderExcelRowByUID = async (
  uid: string,
  key: string,
): Promise<OrderExcelRowResponse> => {
  const response = await axiosJSONInstance.get(`/merchants/order-excels/${uid}/rows/${key}`);
  return response.data;
};

// Update an order Excel row by UID and key
export const updateOrderExcelRow = async (
  uid: string,
  key: string,
  row: Partial<OrderExcelRowRequestUpdate>,
): Promise<OrderResponseExcelRowUpdate> => {
  const response = await axiosJSONInstance.patch(`/merchants/order-excels/${uid}/rows/${key}`, row);
  return response.data;
};
