import { useState, useEffect } from 'react';
import { message } from 'antd';
import { useSetLogin } from '@/hooksApi';
import { useTranslation } from 'react-i18next';
import { convertArabicToEnglishNumbers } from '@/utils';

const useRequestOTP = (phoneNumber: string) => {
  const { t } = useTranslation();

  const { mutate } = useSetLogin();
  const [countdown, setCountdown] = useState(59);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const requestOTP = () => {
    if (countdown !== 0) return;

    mutate(
      {
        phone: convertArabicToEnglishNumbers(phoneNumber),
      },
      {
        onSuccess: () => {
          setCountdown(59);
        },
      },
    );
  };

  return { countdown, requestOTP };
};

export default useRequestOTP;
