import { useFetchPickups } from '@/hooksApi';
import { PickupItem } from '@/types';
import dayjs from 'dayjs';

const usePickupData = (current: number, pageSize?: number) => {
  const { data, isLoading } = useFetchPickups(current, pageSize);
  const dataPickup =
    (data &&
      data?.object?.items.length > 0 &&
      data?.object?.items.map((item: PickupItem | undefined) => ({
        key: item?.uid,
        location: item?.pick_up_address?.title,
        driver: {
          phone: item?.driver?.phone,
          name: `${item?.driver?.first_name || ''} ${item?.driver?.last_name || ''}`,
          assign: Boolean(item?.driver) ?? false,
        },
        status: item?.status?.title,
        platform_code: item?.platform_code,
        pickupTime: dayjs(item?.created_at).format('D MMM YY'),
        pickupLocation: dayjs(item?.created_at).format('H A'),
      }))) ||
    [];

  // totals
  const totals = dataPickup.length;

  return { dataPickup, totals, isLoading };
};

export default usePickupData;
