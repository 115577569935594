import { useSentryMutation, useSentryQuery } from '../utils/sentryQueryWrapper';
import { ContactsResponse, ContactRequest, responseContactByUid } from '@/types';
import {
  createContact,
  deleteContactById,
  fetchContactById,
  fetchContacts,
  updateContactById,
} from '@/api/contactService';
import { useContactsStore } from '@/stores/contactStore';
import { ErrorMessageResponse } from '@/types/error';
import { t } from 'i18next';

// Fetch Contacts
export const useFetchContacts = () => {
  const setContacts = useContactsStore((state) => state.setContacts);

  return useSentryQuery<ContactsResponse, Error>(['contacts'], () => fetchContacts(), {
    onSuccess: (data) => {
      setContacts(data);
    },
    keepPreviousData: true,
  });
};

// Create contact
export const useCreateContact = () => {
  return useSentryMutation<ContactsResponse, ErrorMessageResponse, ContactRequest>(
    (data: ContactRequest) => createContact(data),
    {
      defaultErrorMessage: t('failed_to_add_contact'),
    },
  );
};

// Fetch contact by ID
export const useFetchContact = (id: string) => {
  return useSentryQuery<responseContactByUid, ErrorMessageResponse>(
    ['contacts', id],
    () => fetchContactById(id),
    {
      keepPreviousData: true,
      enabled: !!id,
    },
  );
};

// Update contact By ID
export const useUpdateContact = (id: string) => {
  return useSentryMutation<ContactsResponse, Error, ContactRequest>(
    (data) => updateContactById(id, data),
    {
      onSuccess: (data) => {
        console.log('data', data);
      },
      onError: (error) => {
        console.error('failed: ', error);
      },
    },
  );
};

// Delete contact By ID
export const useDeleteContact = () => {
  return useSentryMutation<ContactsResponse, ErrorMessageResponse, string>(
    (id: string) => deleteContactById(id),
    { defaultErrorMessage: t('failed_to_remove_contact') },
  );
};
