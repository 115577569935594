import { SVGProps } from 'react';

const CloseCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <path
      fill='#38383E'
      d='M8 .667a7.333 7.333 0 1 1 0 14.666A7.333 7.333 0 0 1 8 .667Zm0 1a6.333 6.333 0 1 0 0 12.666A6.333 6.333 0 0 0 8 1.666ZM6.125 5.372 8 7.247l1.875-1.875a.133.133 0 0 1 .095-.039h1.037a.133.133 0 0 1 .094.228L8.707 7.954l2.485 2.485a.133.133 0 0 1-.094.227H10.06a.133.133 0 0 1-.094-.039L8 8.661l-1.966 1.966a.133.133 0 0 1-.094.04H4.903a.133.133 0 0 1-.094-.228l2.484-2.485-2.394-2.393a.133.133 0 0 1 .095-.228H6.03c.035 0 .07.014.094.04Z'
    />
  </svg>
);
export default CloseCircleIcon;
