import { useState } from 'react';
import type { Store } from 'antd/es/form/interface'; // Import the type for the form values
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface'; // Import the correct type for errorInfo
import { LOCAL_AR, LOCAL_EN, ROUTE } from '@/config';
import { useTranslation } from 'react-i18next';
import { useSetRegister } from '@/hooksApi';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@/stores';
import {
  attachCountryCodeToPhone,
  convertArabicToEnglishNumbers,
  getDialCodeByCountryCode,
} from '@/utils';
import useLanguageStore from '@/stores/useLanguageStore';
import type { MenuInfo } from 'rc-menu/lib/interface';

const useRegister = () => {
  // const [direction, setDirection] = useState('ltr');
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const { language, setLanguage } = useLanguageStore();

  const { i18n, t } = useTranslation();
  const navigation = useNavigate();
  const setHash = useUserStore((state) => state.setHash);

  const { mutate, isLoading } = useSetRegister();

  const onFinish = (values: Store) => {
    const phoneNumber = attachCountryCodeToPhone(values.phoneNumber, values.countryCode);

    mutate(
      {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: phoneNumber || '',
      },
      {
        onSuccess: (data) => {
          navigation(ROUTE.VERIFY, { state: { phoneNumber } });
          setHash(data?.object?.hash);
        },
      },
    );
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.error('Failed:', errorInfo);
  };

  const toggleDirection = (e: MenuInfo) => {
    // setDirection((prevDirection) => (prevDirection === 'ltr' ? 'rtl' : 'ltr'));
    i18n.changeLanguage(i18n.language === LOCAL_EN ? LOCAL_AR : LOCAL_EN);
    const language = e.key;
    // i18n.changeLanguage(language);
    setLanguage(language);
  };

  return {
    firstNameInput,
    setFirstNameInput,
    lastNameInput,
    setLastNameInput,
    onFinish,
    onFinishFailed,
    toggleDirection,
    isLoading,
  };
};
export default useRegister;
