import { Checkbox, Flex, Select } from 'antd';
import { Heading } from '../Heading';
import { useCodDropOff } from '@/hooks/useCodDropOff ';
import { useTranslation } from 'react-i18next';
import { PickupTypeEnum } from '@/types';
import { useEffect } from 'react';

const { Option } = Select;

const CodDropOff = () => {
  const { t } = useTranslation();

  const {
    isChecked,
    selectLocation,
    pickupAddresses,
    pickupAddressLoading,
    updatePickupAddressLoading,
    loadingMore,
    handleCheckboxChange,
    handleChange,
    loadMore,
  } = useCodDropOff();

  const listCodDropOff = pickupAddresses?.filter((item) => item.type === PickupTypeEnum.COD);
  const defaultDropOff = listCodDropOff?.find((cod) => +cod.default === 1)?.uid;

  useEffect(() => {
    if (!isChecked && defaultDropOff) {
      handleChange?.(String(defaultDropOff), false);
    }
  }, [isChecked]);

  useEffect(() => {
    if (!pickupAddressLoading && defaultDropOff) {
      handleCheckboxChange(true);
    }
  }, [pickupAddressLoading, defaultDropOff]);

  return (
    !pickupAddressLoading && (
      <Flex vertical className='mt-[27px]'>
        <Flex className='flex items-center gap-x-2'>
          <Checkbox
            className='custom-checkbox'
            onChange={(e) => handleCheckboxChange(e.target.checked)}
            checked={isChecked}
          />
          <Heading
            title={t('cod_drop_off')}
            fontSize='text-lg'
            fontWeight='font-bold'
            extraClassName='uppercase'
          />
        </Flex>
        {isChecked && (
          <Select
            loading={pickupAddressLoading || updatePickupAddressLoading}
            placeholder={t('cod_drop_off')}
            listHeight={250}
            listItemHeight={10}
            defaultValue={defaultDropOff}
            value={selectLocation}
            onChange={(value) => handleChange?.(value)}
            className='bg-white w-full h-[80px] rounded-[10px] mt-6'
            dropdownRender={(menu) => (
              <Flex onScroll={loadMore} style={{ maxHeight: 200, overflowY: 'auto' }} vertical>
                {menu}
                {loadingMore && (
                  <Flex justify='center' style={{ padding: '8px 0' }}>
                    Loading more...
                  </Flex>
                )}
              </Flex>
            )}
          >
            {listCodDropOff.map((item) => (
              <Option value={item.uid} key={item.uid}>
                {item?.title}
              </Option>
            ))}
          </Select>
        )}
      </Flex>
    )
  );
};

export default CodDropOff;
