import React from 'react';
import { Button, Flex, Form } from 'antd';
import { Card } from '../Card';
import { Heading } from '../Heading';
import { AddIcon, PickupsIcon } from '../Icons';

// Define an interface for the props
interface BottomOrderCardProps {
  createOrderTitle: string;
  readyToShipTitle: string;
  addOrderLoading?: 'creating' | 'readyToShip';
  disable?: boolean;
  handleSubmit: (value: boolean) => void;
}

const BottomOrderCard: React.FC<BottomOrderCardProps> = ({
  createOrderTitle,
  readyToShipTitle,
  addOrderLoading,
  disable,
  handleSubmit,
}) => {
  return (
    <Card extraClassName='py-[19px]'>
      <Flex gap='middle' justify='flex-end' align='center'>
        <Button
          loading={addOrderLoading === 'readyToShip'}
          onClick={() => handleSubmit(true)}
          disabled={!disable || !!addOrderLoading} // Disable based on isFormReady
          style={{ paddingBottom: '27px', paddingTop: '27px' }}
          className='rounded-[8px] border-solid  border-customTunaPrimary disabled:opacity-60 '
        >
          {addOrderLoading !== 'readyToShip' && <PickupsIcon width={20} height={20} />}
          <Heading
            title={readyToShipTitle}
            fontWeight='font-bold'
            fontSize='text-sm'
            extraClassName=' uppercase leading-5 text-customTunaPrimary'
          />
        </Button>
        <Form.Item className='!mb-0'>
          <Button
            loading={addOrderLoading === 'creating'}
            // htmlType='submit'
            onClick={() => handleSubmit(false)}
            disabled={!disable || !!addOrderLoading} // Disable based on isFormReady
            type='primary'
            className='rounded-[8px] disabled:bg-yellow-100 gap-x-2'
            style={{ paddingBottom: '27px', paddingTop: '27px' }}
          >
            {!addOrderLoading && <AddIcon width={20} height={20} />}
            <Heading
              title={createOrderTitle}
              fontWeight='font-bold'
              fontSize='text-sm'
              extraClassName=' uppercase leading-5 text-customTunaPrimary'
            />
          </Button>
        </Form.Item>
      </Flex>
    </Card>
  );
};

export default BottomOrderCard;
