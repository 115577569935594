import { Button, Col, Flex, Image, Layout, Row, Spin, Menu, Dropdown } from 'antd';
import { LoginFormCard, LoginImageCard } from '@/components';
import useLogin from '@/hooks/useLogin';
import boxyLogo from '/boxy-logo.svg';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import { LOCAL_AR, LOCAL_EN } from '@/config';

const Login = () => {
  const { onFinish, onFinishFailed, toggleDirection, isLoading } = useLogin();
  const { t, i18n } = useTranslation();

  // State to track image loading status
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  // Language menu with English and Arabic options
  const languageMenu = (
    <Menu onClick={toggleDirection}>
      <Menu.Item key={LOCAL_EN}>English</Menu.Item>
      <Menu.Item key={LOCAL_AR}>العربية</Menu.Item>
    </Menu>
  );
  return (
    <Layout className='flex justify-center items-center min-h-screen bg-customYellowPrimary'>
      <Flex vertical className='-mt-6'>
        {isImageLoaded && (
          <>
            <Col className='flex justify-center items-center'>
              <Image
                width={'160px'}
                height={'74.1px'}
                src={boxyLogo}
                alt='register Visual'
                preview={false}
              />
            </Col>
            {/* <Button onClick={toggleDirection} className="absolute top-6 right-6">
            Change Language
          </Button> */}
            <Dropdown overlay={languageMenu} trigger={['click']} className='absolute top-6 right-6'>
              <Button icon={<GlobalOutlined style={{ fontSize: '26px' }} />} type='text'></Button>
            </Dropdown>
          </>
        )}
        {/* Show loader/spinner until the image is fully loaded */}
        {!isImageLoaded && (
          <Flex className='flex justify-center items-center h-full'>
            <Spin size='large' fullscreen />
          </Flex>
        )}

        {/* Image and Form container */}
        <Flex className={`max-h-[78vh] mt-[40px] ${!isImageLoaded ? 'hidden' : ''}`}>
          <Row
            className={`shadow-deep-shadow rounded-l-[20px] overflow-hidden ${
              i18n.language === LOCAL_EN ? 'rounded-l-[20px]' : 'rounded-r-[20px]'
            }`}
          >
            {/* Only render the form when the image is fully loaded */}
            {isImageLoaded && (
              <LoginFormCard
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                loginText={t('login')}
                phoneNumberPlaceholder={t('phone_number')}
                loginButtonText={t('login')}
                registerText={t('do_not_have_account')}
                registerLinkText={t('registerUpp')}
                isLoading={isLoading}
              />
            )}
            {/* LoginImageCard passes the onLoad handler to update state */}
            <LoginImageCard onImageLoad={() => setIsImageLoaded(true)} />
          </Row>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Login;
