import { useTranslation } from 'react-i18next';
import { Flex, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuthStore } from '@/stores/authStore';
import { ROUTE } from '@/config';

const MagicToken = () => {
  const params = useParams();
  const setToken = useAuthStore((state) => state.setToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.magicToken) {
      setToken(params.magicToken);
      navigate(ROUTE.ORDERS);
    } else {
      navigate(ROUTE.LOGIN);
    }
  }, []);
  return (
    <Flex className='flex justify-center items-center h-full'>
      <Spin size='large' fullscreen />
    </Flex>
  );
};

export default MagicToken;
