import React, { useRef } from 'react';
import { CheckboxRef, Col, Flex, Radio, Typography } from 'antd';
import { BoxLargeIcon, BoxMediumIcon, BoxSmallIcon } from '../Icons';
import { useTranslation } from 'react-i18next';
import { LOCAL_EN } from '@/config';

const { Text } = Typography;

interface PackageSizeProps {
  size: 'small' | 'medium' | 'large';
  description: string;
  selectedValue: string;
  onSelect: (value: string) => void;
}

const PackageSize: React.FC<PackageSizeProps> = ({
  size,
  description,
  selectedValue,
  onSelect,
}) => {
  const radioRef = useRef<CheckboxRef>(null);
  const { i18n } = useTranslation();
  const iconColor = selectedValue === size ? '#38383E' : '#939496';

  const handleToggle = () => {
    if (radioRef.current?.input) {
      onSelect(size);
      radioRef.current.input.checked = true;
    }
  };
  return (
    <Flex
      className={`h-[113px] py-1 rounded-[10px] flex flex-col justify-center items-center cursor-pointer  ${
        selectedValue === size
          ? 'bg-customYellowPrimary border border-solid border-customTunaPrimary'
          : 'border border-solid border-customGrayShade4'
      }`}
      onClick={handleToggle}
    >
      {size === 'small' && <BoxSmallIcon color={iconColor} width={35} height={39} />}
      {size === 'medium' && <BoxMediumIcon color={iconColor} width={35} height={39} />}
      {size === 'large' && <BoxLargeIcon color={iconColor} width={35} height={39} />}

      <Text
        className={` capitalize ${
          selectedValue === size ? 'font-bold' : 'font-normal'
        } font-bold text-base leading-[24.48px] text-customTunaPrimary`}
      >
        {size}
      </Text>
      <Text
        className={` font-normal ${
          selectedValue === size ? 'text-customYellowDark' : 'text-customGrayShade5'
        }  text-xs leading-[18px]`}
      >
        {description}
      </Text>
      <Col className='pb-2'>
        <Radio
          ref={radioRef}
          value={size}
          checked={selectedValue === size}
          className={`custom-radio ${i18n.language === LOCAL_EN ? 'pl-2' : 'pr-2'}  ${
            selectedValue === size ? 'text-customTunaPrimary' : 'text-customGrayShade5'
          }  flex items-center font-bold text-lg leading-[20.88px]`}
        ></Radio>
      </Col>
    </Flex>
  );
};

export default PackageSize;
