import { Error } from '@/components';
import errorImage from '/server-error.svg';
import { useTranslation } from 'react-i18next';

const NotVerified = () => {
  const { t } = useTranslation();
  return (
    <div className='mt-20'>
      <Error
        imageSrc={errorImage}
        errorTitle={t('not_verified')}
        errorMessage={t('contact_to_support')}
        hasGoLoginBtn
      />
    </div>
  );
};

export default NotVerified;
