import React from 'react';
import { Button, Flex, Image, Typography } from 'antd';
import { ArrowLeftIcon, Heading } from '@/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '@/config';

const { Text } = Typography;

interface ClientErrorProps {
  imageSrc: string;
  errorTitle: string;
  errorMessage?: string;
  hasGoLoginBtn?: boolean;
}

const Error: React.FC<ClientErrorProps> = ({
  imageSrc,
  errorTitle,
  errorMessage,
  hasGoLoginBtn,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex className='max-h-screen flex justify-center pt-10'>
      <Flex vertical className='items-center'>
        <Image src={imageSrc} preview={false} />
        <Text className='font-bold text-lg leading-[20.88px] text-customTunaPrimary mt-[26px]'>
          {errorTitle}
        </Text>
        <Text className='font-normal text-sm leading-[18.2px] text-customGrayShade5 mt-2 mb-[18px]'>
          {errorMessage ?? t('sorry_something')}
        </Text>
        <Button onClick={() => navigate(hasGoLoginBtn ? ROUTE.LOGIN : '/')} type='primary'>
          <Heading
            leadingIcon={<ArrowLeftIcon />}
            title={hasGoLoginBtn ? t('back_to_login') : t('back_home')}
            fontWeight='font-bold'
            fontSize='text-xs'
            extraClassName='leading-5 uppercase'
          />
        </Button>
      </Flex>
    </Flex>
  );
};

export default Error;
