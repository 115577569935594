import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Flex, Layout, MenuProps, Row } from 'antd';
import {
  BottomSheet,
  TableAction,
  DownloadIcon,
  FilterHeader,
  PickupsIcon,
  Heading,
  ExportIcon,
  ResolveIcon,
} from '@/components';
import TabBar from '@/components/Tab/TabBar';
import { useTranslation } from 'react-i18next';
import { getColumns, getListColumns } from './list.tsx';
import { OrderStatusTab } from '@/types/columns.ts';
import styles from './orders.module.css';
import useOrderData, { OrderDataType } from '@/hooks/useOrderData.ts';
import { listStatusButtons } from '@/utils/getStatus.ts';
import useSubmitOrder from '@/hooks/useSubmitOrders.tsx';
import useTabVisibility from '@/hooks/useTabVisibility.ts';
import { ISearch } from '@/components/TableAction/FilterHeader.tsx';
import useSearchStore from '@/hooks/useSearchStore.ts';
import useOrderListData from '@/hooks/useOrderListData.tsx';
import getTabItems from '@/utils/getTabsItems.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderColumn } from '@/types/order.ts';
import { downloadFile } from '@/utils/helper.ts';
import { fetchBulkOrderByQuery } from '@/api/orderService.ts';
import BulkResolveModal from '@/components/ResolveModal/BulkResolveModal.tsx';

const pageSize = 25;

const Orders = () => {
  const [isBulkResolveOpen, setIsBulkResolveOpen] = useState(false);
  const { i18n, t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  // parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const activeTabQuery = queryParams.get('activeTab') as OrderStatusTab;
  const activeSubTabQuery = queryParams.get('activeSubTab') as OrderStatusTab;

  // search value
  const [searchValue, setSearchValue] = useState<ISearch>({
    search: null,
    searchBy: 'filter_platform_code',
  });

  // Zustand store values search
  const { searchValue: searchTermHeader } = useSearchStore(); //TODO

  // handle active checkbox Item
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRow, setSelectedRow] = useState<(OrderDataType & OrderColumn)[]>([]);
  const [current, setCurrent] = useState<number>(1);

  // find active tab and sub tab
  const [activeTab, setActiveTab] = useState<OrderStatusTab>(OrderStatusTab.ReadyToShip);
  const [activeSubTab, setActiveSubTab] = useState<string>('all');

  // filter
  const [currentAll, setCurrentAll] = useState<number>(1);

  // data for table list orders
  const { listData, loadingOrderList, totalsOrderList } = useOrderListData(
    currentAll,
    pageSize,
    searchTermHeader,
  );

  // set current page when search change
  useEffect(() => {
    if (searchTermHeader?.search || searchTermHeader?.searchBy) {
      setCurrentAll(1);
    }
  }, [searchTermHeader?.search, searchTermHeader?.searchBy]);

  useEffect(() => {
    // if user changes the url manually
    if (isValidOrderStatusTab(activeTabQuery) && activeTab !== activeTabQuery) {
      setActiveTab(activeTabQuery);
      if (listStatusButtons[activeTabQuery].some((item) => item.value === activeSubTabQuery))
        setActiveSubTab(activeSubTabQuery ?? 'all');
    }
  }, [activeTabQuery, activeSubTabQuery]);

  // data tables
  const { activeData, totals, isLoading, count, refetch } = useOrderData(
    current,
    activeTab,
    activeSubTab,
    pageSize,
    searchValue,
  );

  // handle request pickup
  const { onSubmit, LoadingRequestPickup } = useSubmitOrder(activeTab, selectedRow);

  // handle row selection
  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  }, [activeTab]);

  // Handle when the tab becomes visible again
  const handleTabVisible = () => {
    refetch();
  };

  function isValidOrderStatusTab(value: string): boolean {
    return Object.values(OrderStatusTab).includes(value as OrderStatusTab);
  }

  // Hook to handle tab visibility
  useTabVisibility(() => {}, handleTabVisible);

  // active button
  const checkActive = (item: string) => (activeSubTab === item ? 'primary' : 'default');

  // handle search
  const handleSearchChange = useCallback((value: ISearch) => {
    setSearchValue(value);
    setCurrent(1);
  }, []);

  // tabs items
  const tabItems = getTabItems({
    t,
    i18n,
    checkActive,
    handleChangeSubTab,
    count,
  });

  // handle onChange pagination
  const handlePaginationChange = (page: number) => {
    setCurrent(page);
  };

  // handle All onChange pagination
  const handlePaginationAllChange = (page: number) => {
    setCurrent(page);
  };

  // handle row selection
  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRow([]);
  }, [activeTab]);

  const actionFilter = activeData?.filter((item) => item?.merchant_action_required === 1);
  const checkAction = OrderStatusTab.OnHold === activeTab && activeSubTab === 'action_required';

  // has search header
  const hasSearchHeader = searchTermHeader?.search === null || searchTermHeader?.search === '';

  // menu items dropdown
  const onClickDropdown: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'export') {
      const queryString = selectedRow
        .map((row: OrderColumn) => `filter_uids[]=${encodeURIComponent(row?.key)}`)
        .join('&');

      await downloadFile(queryString, fetchBulkOrderByQuery, `orders-${new Date().getTime()}.csv`);
    }
  };

  const handleChangeTab = (activeTabKey: OrderStatusTab) => {
    setCurrent(1);
    setActiveSubTab('all');
    setActiveTabQueryString(activeTabKey);
  };

  function handleChangeSubTab(subTabKey: string) {
    setCurrent(1);
    setActiveSubTab(subTabKey);
    setActiveTabQueryString(activeTab, subTabKey);
  }

  const setActiveTabQueryString = (activeTabKey: OrderStatusTab, activeSubTabKey?: string) => {
    const params = new URLSearchParams({
      activeTab: activeTabKey,
    });
    if (activeSubTabKey) params.append('activeSubTab', activeSubTabKey);

    navigate(`?${params.toString()}`);
  };
  const menuItems: MenuProps['items'] = [
    {
      key: 'export',
      label: (
        <Heading
          title={t('export_selected_orders')}
          leadingIcon={<ExportIcon />}
          rotation={false}
          loading={isLoading}
        />
      ),
    },
  ];
  const tableData = checkAction ? actionFilter : activeData;
  const hasResolveOnHold = tableData.some((item) => !!item.merchant_action_required);

  const tabBarContent = (
    <>
      <Row className='w-full my-6'>
        <Col md={24} xs={24}>
          <TabBar
            activeTab={activeTab}
            setActiveTab={setActiveTab as any}
            handleChange={(tabKey) => handleChangeTab(tabKey as OrderStatusTab)}
            tabItems={tabItems}
          />
        </Col>
        <Col md={24} xs={24} className='mt-6 mb-16 z-10'>
          <TableAction<OrderDataType & OrderColumn>
            columns={getColumns(activeTab, activeSubTab, t) || []}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            setSelectedRow={setSelectedRow}
            data={checkAction ? actionFilter : activeData}
            pageSize={pageSize}
            current={current}
            loading={checkAction ? false : isLoading}
            total={totals}
            onPaginationChange={handlePaginationChange}
            rowSelectionEnabled
            header={
              <>
                <Flex justify='end'>
                  {activeTab === OrderStatusTab.OnHold && hasResolveOnHold && (
                    <Button
                      onClick={() => setIsBulkResolveOpen(true)}
                      type='primary'
                      className='px-5'
                    >
                      <Heading
                        title={t('bulk_resolve')}
                        leadingIcon={<ResolveIcon />}
                        fontSize='text-xs'
                        fontWeight='font-bold'
                        extraClassName='uppercase leading-[20px]'
                        rotation={false}
                      />
                    </Button>
                  )}
                </Flex>
                <FilterHeader
                  includeSearchBox={activeTab === OrderStatusTab.RTO ? false : true}
                  filterItems={[
                    activeTab === OrderStatusTab.OnHold ? t('on_hold') : t('order'),
                    t('customer'),
                  ]}
                  setSearchValue={handleSearchChange}
                  searchValue={searchValue}
                />
              </>
            }
          />
        </Col>
      </Row>
      <Row className={`sticky bottom-6 ${selectedRowKeys.length > 0 ? 'z-50' : '-z-50'}`}>
        <div className='relative block w-full overflow-hidden h-[90px]'>
          <BottomSheet
            withoutButton={[OrderStatusTab.Finished, OrderStatusTab.RTO].includes(activeTab)}
            isVisible={selectedRowKeys.length > 0}
            numberSelected={selectedRowKeys.length}
            total={totals}
            labelBtn={
              activeTab === OrderStatusTab.InProgress || activeTab === OrderStatusTab.OnHold
                ? t('download')
                : t('ship_now')
            }
            Icon={
              activeTab === OrderStatusTab.InProgress || activeTab === OrderStatusTab.OnHold ? (
                <DownloadIcon />
              ) : (
                <PickupsIcon width={20} height={20} />
              )
            }
            onSubmit={onSubmit}
            loadingSubmit={LoadingRequestPickup}
            menuItems={menuItems}
            onClickDropdown={onClickDropdown}
          />
        </div>
      </Row>

      <BulkResolveModal isOpen={isBulkResolveOpen} onClose={() => setIsBulkResolveOpen(false)} />
    </>
  );

  const listContent = (
    <>
      <TableAction
        columns={getListColumns(t)}
        data={listData || []}
        pageSize={pageSize}
        current={currentAll}
        loading={loadingOrderList}
        total={totalsOrderList}
        onPaginationChange={handlePaginationAllChange}
        rowSelectionEnabled
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        setSelectedRow={setSelectedRow}
        className='mt-2'
      />
      <Row className='sticky bottom-6'>
        <div className='relative block w-full overflow-hidden h-[90px]'>
          <BottomSheet
            isVisible={selectedRowKeys.length > 0}
            numberSelected={selectedRowKeys.length}
            withoutButton
            total={totals}
            menuItems={menuItems}
            onClickDropdown={onClickDropdown}
          />
        </div>
      </Row>
    </>
  );

  return (
    <Layout className='overflow-visible relative w-full' rootClassName={styles.antLayoutCustom}>
      {hasSearchHeader ? tabBarContent : listContent}
    </Layout>
  );
};

export default Orders;
