import { ErrorData } from '@/types';
import { message } from 'antd';
import i18next from 'i18next';

export const handleErrorMessage = (
  error: ErrorData,
  defaultErrorMessage?: string,
  isMutation?: boolean,
) => {
  const { statusCode, message: errorMessage, object } = error;

  if (statusCode === 422 && object) {
    // This condition should be checked with backend team
    for (const key in object) {
      if (Array.isArray(object[key])) {
        message.error(object[key]);
      }
    }
  } else {
    const generalErrorMessage =
      errorMessage ||
      defaultErrorMessage ||
      i18next.t(isMutation ? 'operation_failed' : 'unexpected_error');

    message.error(generalErrorMessage);
  }
};
