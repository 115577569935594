import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Flex, Form, FormInstance, Row } from 'antd';

import { Card } from '../Card';
import { PackageSize } from '../PackageSize';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface PackageSizeOption {
  size: 'small' | 'medium' | 'large';
  description: string;
}

interface PackageSizeCardProps {
  title: string;
  form: FormInstance; // Accept form instance from parent
  onPackageSizeChange: (size: string) => void; // New prop to handle size change
  packageSizeProp?: string;
}

const PackageSizeEditCard: React.FC<PackageSizeCardProps> = ({
  title,
  form,
  onPackageSizeChange,
  packageSizeProp,
}) => {
  const [selectedSize, setSelectedSize] = useState<string>('S'); // State to manage selected size
  const [isFragileChecked, setIsFragileChecked] = useState(false); // Track the checkbox state
  const { t } = useTranslation();

  const options: PackageSizeOption[] = [
    { size: 'small', description: t('up_to_one_kg') },
    { size: 'medium', description: t('up_to_three_kg') },
    { size: 'large', description: t('up_to_five_kg') },
  ];
  // Map size to the required form values (S, M, L)
  const mapSizeToFormValue = (size: string) => {
    switch (size) {
      case 'small':
        return 'S';
      case 'medium':
        return 'M';
      case 'large':
        return 'L';
      default:
        return '';
    }
  };

  // Map form values back to size (for reverse lookup)
  const mapFormValueToSize = (value: string) => {
    switch (value) {
      case 'S':
        return 'small';
      case 'M':
        return 'medium';
      case 'L':
        return 'large';
      default:
        return '';
    }
  };

  const handleSelect = async (size: string) => {
    setSelectedSize(size);
    const mappedSize = mapSizeToFormValue(size);
    form.setFieldsValue({ packageSize: mappedSize }); // Update form value to S, M, or L
    onPackageSizeChange(mappedSize); // Call the function to update price
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsFragileChecked(e.target.checked);
  };

  // Sync the selected size when the form initializes or updates
  useEffect(() => {
    const initialSize = packageSizeProp || form.getFieldValue('packageSize'); // Use packageSizeProp if available
    if (initialSize) {
      const mappedSize = mapFormValueToSize(initialSize); // Convert form value to the corresponding size
      setSelectedSize(mappedSize); // Set the selected size
      form.setFieldsValue({ packageSize: initialSize }); // Set the form value to "S", "M", or "L"
    }
  }, [form, packageSizeProp]);

  return (
    <Form.Item name='packageSize'>
      <Card>
        <Heading
          title={title}
          fontSize='text-lg'
          fontWeight='font-bold'
          extraClassName='uppercase'
        />
        <Row gutter={[24, 16]} className='mt-3'>
          {options.map((option) => (
            <Col key={option.size} lg={8} md={24} xs={24}>
              <PackageSize
                size={option.size}
                description={option.description}
                selectedValue={selectedSize}
                onSelect={handleSelect} // Call handleSelect to update state and form
              />
            </Col>
          ))}
        </Row>
        <Flex className='flex items-center gap-x-2 mt-4'>
          <Form.Item
            initialValue={isFragileChecked} // Set the initial value
            className='mt-[6px] mb-0'
            name='isFragile'
            valuePropName='checked'
          >
            <Checkbox
              checked={form.getFieldValue('isFragile')}
              onChange={handleCheckboxChange}
              className='custom-checkbox'
            >
              <Heading
                title={t('package_content_is_fragile')}
                fontSize='text-base'
                fontWeight='font-normal'
                extraClassName='leading-[20.48px]'
              />
            </Checkbox>
          </Form.Item>
        </Flex>
      </Card>
    </Form.Item>
  );
};

export default PackageSizeEditCard;
