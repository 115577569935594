import { BasicProduct } from '@/types';
import create from 'zustand';

interface ProductStore {
  products: BasicProduct[];
  setProducts: (products: BasicProduct[]) => void;
}

const useProductStore = create<ProductStore>((set) => ({
  products: [],
  setProducts: (products) => set({ products }),
}));

export default useProductStore;
