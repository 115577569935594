import React, { useEffect, useState } from 'react';
import { Col, Flex, Form, FormInstance, Radio, RadioChangeEvent, Row, Typography } from 'antd';
import { Card } from '../../Card';
import { Heading } from '../../Heading';
import { useTranslation } from 'react-i18next';
import { CustomRadioButton } from '../../CustomRadioButton';
import SwitchWalletCard from './SwitchWalletCard';
import DiscountInputCard from './DiscountInputCard';
import OrderShipmentValueCard from './OrderShipmentValueCard';
import CustomShipmentFeeCard from '../../AddNewOrderCards/CustomShipmentFeeCard';
import { Preview } from '@/types';

const { Text } = Typography;

interface PaymentCardProps {
  form: FormInstance; // Add form instance prop
  title: string;
  orderTitle: string;
  codTitle: string;
  prepaidTitle: string;
  shipmentFeeTitle: string;
  customerTitle: string;
  merchantTitle: string;
  walletBalanceText: string;
  availableText: string;
  iqdText: string;
  discountPlaceholder: string;
  orderPrice: Preview;
  orderPriceLoading: boolean;
  CodOrPrepaid?: string; // Add CodOrPrepaid as a typed prop
  CustomerOrMerchant?: string; // Add CustomerOrMerchant as a typed prop
  onCustomShipmentFeeChange: (shipmentFee?: number) => void;
  customShipmentFee?: number;
}

export const PaymentCard: React.FC<PaymentCardProps> = ({
  form,
  title,
  orderTitle,
  codTitle,
  prepaidTitle,
  shipmentFeeTitle,
  customerTitle,
  merchantTitle,
  walletBalanceText,
  availableText,
  iqdText,
  discountPlaceholder,
  orderPrice,
  orderPriceLoading,
  CodOrPrepaid,
  CustomerOrMerchant,
  onCustomShipmentFeeChange,
  customShipmentFee,
}) => {
  const { t } = useTranslation();
  const [discountCode, setDiscountCode] = useState(''); // State to track the value of DiscountInputCard
  const [selectedCodOrPrepaidValue, setSelectedCodOrPrepaidValue] = useState('COLLECT_ON_DELIVERY'); // State to track the selected radio
  const [selectedCustomerOrMerchantValue, setSelectedCustomerOrMerchantValue] =
    useState('BY_CUSTOMER'); // State to track the selected radio

  useEffect(() => {
    if (CodOrPrepaid && CustomerOrMerchant) {
      form.setFieldsValue({
        CodOrPrepaid: CodOrPrepaid,
        CustomerOrMerchant: CustomerOrMerchant,
      });
      setSelectedCodOrPrepaidValue(CodOrPrepaid || '');
      setSelectedCustomerOrMerchantValue(CustomerOrMerchant || '');
    }
  }, [CodOrPrepaid, CustomerOrMerchant]);

  const handleRadioChangeCodOrPrepaid = (e: RadioChangeEvent) => {
    setSelectedCodOrPrepaidValue(e.target.value);
  };

  const handleRadioChangeCustomerOrMerchant = (e: RadioChangeEvent) => {
    setSelectedCustomerOrMerchantValue(e.target.value);
  };

  return (
    <Card>
      <Heading title={title} fontSize='text-lg' fontWeight='font-bold' extraClassName='uppercase' />
      <Flex vertical className='mt-4'>
        <Text className='font-normal mb-2 text-xs leading-[10px] text-customGrayShade5 capitalize'>
          {orderTitle}
        </Text>

        <Form.Item name='CodOrPrepaid' initialValue={CodOrPrepaid}>
          <Radio.Group
            value={selectedCodOrPrepaidValue}
            onChange={handleRadioChangeCodOrPrepaid}
            className='w-full'
          >
            <Row gutter={[24, 16]} className='mt-[19.5px]'>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value='COLLECT_ON_DELIVERY'
                  selectedValue={selectedCodOrPrepaidValue}
                  label={codTitle}
                />
              </Col>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value='PREPAID'
                  selectedValue={selectedCodOrPrepaidValue}
                  label={prepaidTitle}
                />
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Flex>
      <Flex vertical className='mt-4'>
        <Text className='font-normal mb-2 text-xs leading-[10px] text-customGrayShade5 capitalize'>
          {shipmentFeeTitle}
        </Text>
        <Form.Item name='CustomerOrMerchant' initialValue={CustomerOrMerchant}>
          <Radio.Group
            value={selectedCustomerOrMerchantValue}
            onChange={handleRadioChangeCustomerOrMerchant}
            className='w-full'
          >
            <Row gutter={[24, 16]} className='mt-[19.5px]'>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value='BY_CUSTOMER'
                  selectedValue={selectedCustomerOrMerchantValue}
                  label={customerTitle}
                />
              </Col>
              <Col lg={12} md={24} xs={24}>
                <CustomRadioButton
                  value='BY_MERCHANT'
                  selectedValue={selectedCustomerOrMerchantValue}
                  label={merchantTitle}
                />
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Flex>
      {selectedCustomerOrMerchantValue === 'BY_CUSTOMER' && (
        <CustomShipmentFeeCard
          onCustomShipmentFeeChange={onCustomShipmentFeeChange}
          customShipmentFee={customShipmentFee}
        />
      )}
      {/* V0 remove */}
      {/* <SwitchWalletCard
        availableIqd={1}
        walletBalanceText={walletBalanceText}
        availableText={availableText}
        iqdText={iqdText}
      />
      <DiscountInputCard
        placeholder={discountPlaceholder}
        value={discountCode}
        onChange={setDiscountCode}
      /> */}
      <OrderShipmentValueCard
        orderValue={orderPrice.orders_value}
        shipmentFee={orderPrice.orders_shipment_fee}
        customShipmentFee={orderPrice.orders_custom_shipment_fee}
        customShipmentFeeText={t('customer_shipping_fee')}
        // walletFee={-2000}
        // walletFeeText={t('paid_from_wallet')}
        isShipmentFeeByCustomer={selectedCustomerOrMerchantValue === 'BY_CUSTOMER'}
        isCollectOnDelivery={selectedCodOrPrepaidValue === 'COLLECT_ON_DELIVERY'}
        total={orderPrice.orders_value_with_shipment_fee}
        orderPriceLoading={orderPriceLoading}
        orderValueText={t('order_value')}
        shipmentFeeText={t('shipment_fee')}
        totalText={t('total')}
        currency={t('iqd')}
      />
    </Card>
  );
};
