import { DEFAULT_COUNTRY_CODE } from '@/config';
import { countries } from '@/utils/CountryCodes';
import { Select, Input, Image, Form } from 'antd';

interface Props {
  countryCodeName: string;
  phoneNumberName: string;
  phoneNumberPlaceholder: string;
  required?: boolean;
  onChange?: (value: string) => void;
  showCountryCode?: boolean;
}
export const PhoneNumberInput = ({
  required = true,
  countryCodeName,
  phoneNumberName,
  phoneNumberPlaceholder,
  onChange,
  showCountryCode = true,
}: Props) => {
  return (
    <>
      {showCountryCode && (
        <Form.Item
          name={countryCodeName}
          initialValue={DEFAULT_COUNTRY_CODE}
          rules={[{ required, message: '' }]}
        >
          <Select
            variant='borderless'
            style={{ direction: 'ltr' }}
            className='border border-solid custom-select-item custom-select-placeholder border-customGrayShade5 h-[56px] rounded-[10px]'
            dropdownStyle={{ borderColor: 'red', borderWidth: '2px' }}
            showSearch
          >
            {countries.map((country, index) => (
              <Select.Option key={index} value={country.dialCode}>
                <span role='img'>
                  <Image preview={false} src={country.image} width={20} height={20} />
                </span>{' '}
                (+{country.dialCode})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        className='w-full'
        // style={{ height: '80px' }}
        name={phoneNumberName}
        rules={[{ required, message: '' }]}
      >
        <Input
          suffix={required ? <span className='text-customRed text-base'>*</span> : undefined}
          bordered={false}
          autoComplete='off'
          style={{ direction: 'ltr' }}
          placeholder={phoneNumberPlaceholder}
          onChange={(e) => onChange?.(e.target.value)}
          className='border border-solid custom-form-placeholder-input  relative border-customGrayShade5 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg'
        />
      </Form.Item>
    </>
  );
};
