import { SVGProps } from 'react';

interface ExportIcon2Props extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const ExportIcon2 = ({
  width = 18,
  height = 13,
  color = '#38383E',
  ...props
}: ExportIcon2Props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.39051 6.19334L7.26981 7.31394C7.02584 7.55784 6.62997 7.55784 6.38601 7.31394C6.14205 7.07004 6.14205 6.67401 6.38601 6.43011L8.57348 4.24252C8.81772 3.99849 9.21331 3.99849 9.45728 4.24252L11.645 6.43011C11.8887 6.67401 11.8887 7.07004 11.645 7.31394C11.4011 7.55784 11.0049 7.55784 10.761 7.31394C10.761 7.31394 10.1414 6.69435 9.64053 6.19334V12.1844C9.64053 12.5295 9.36043 12.8094 9.01538 12.8094C8.67061 12.8094 8.39051 12.5295 8.39051 12.1844V6.19334ZM4.95302 8.43454C5.29778 8.43454 5.57789 8.71462 5.57789 9.05954C5.57789 9.40447 5.29778 9.68455 4.95302 9.68455H2.38068C1.59091 9.68455 0.949667 9.0433 0.949667 8.25363V1.80601C0.949667 1.01634 1.59091 0.37509 2.38068 0.37509L15.6354 0.37509C16.4252 0.37509 17.0664 1.01634 17.0664 1.80601V8.25363C17.0664 9.0433 16.4252 9.68455 15.6354 9.68455H13.078C12.733 9.68455 12.4529 9.40447 12.4529 9.05954C12.4529 8.71462 12.733 8.43454 13.078 8.43454H15.6354C15.7353 8.43454 15.8164 8.35346 15.8164 8.25363L15.8164 1.80601C15.8164 1.70618 15.7353 1.6251 15.6354 1.6251L2.38068 1.6251C2.28073 1.6251 2.19969 1.70618 2.19969 1.80601L2.19969 8.25363C2.19969 8.35346 2.28073 8.43454 2.38068 8.43454H4.95302Z'
      fill='#38383E'
    />
  </svg>
);
export default ExportIcon2;
