import { Button, Flex, Input, notification, Select } from 'antd';
import { AddIcon, ImportIcon, SearchIcon, ExportIcon2 } from '../Icons';
import { Heading } from '../Heading';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '@/config';
import { getSearchItems } from '@/utils/getSearchItems ';
import useSearchStore from '@/hooks/useSearchStore';
import debounce from 'lodash.debounce';
import { useState } from 'react';
import { ExportModal } from './ExportModal/ExportModal';
import dayjs from 'dayjs';
import { downloadFile } from '@/utils';
import { fetchBulkOrderByQuery } from '@/api';

const { Option } = Select;

const ButtonHeaderOrders = () => {
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchItems = getSearchItems(t);

  // store search in
  const { searchValue, setSearchValue } = useSearchStore();

  // Handle select change
  const handleSelectChange = (value: string) => {
    setSearchValue({ ...searchValue, searchBy: value });
  };

  // Handle search input change
  const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({ ...searchValue, search: e.target.value });
  }, 500);

  const handleExport = async (
    selectedDateRange?: string[],
    selectedMonthRange?: string[],
    selectedStatuses?: string[],
  ) => {
    const dates = selectedDateRange ?? selectedMonthRange;

    const queryParts = [
      `filter_created_at_from=${dates ? dates[0] : dayjs().subtract(1, 'month').format('YYYY-MM-DD')}`,
      `filter_created_at_to=${dates ? dates[1] : dayjs().format('YYYY-MM-DD')}`,
    ];
    if (selectedStatuses) {
      selectedStatuses.forEach((status) => {
        queryParts.push(`filter_statuses[]=${status}`);
      });
    }

    const isSuccessful = await downloadFile(
      queryParts.join('&'),
      fetchBulkOrderByQuery,
      `order-${new Date().getTime()}.csv`,
    );
    if (isSuccessful) {
      notification.success({
        message: t('success'),
        description: t('operation_completed_successfully'),
      });
    }
    //  else {
    //   notification.error({
    //     message: t('error'),
    //     description: t('operation_failed_please_try_again'),
    //   });
    // }
  };

  return (
    <Flex gap='middle' justify='space-between' className='mt-2 sm:mb-2'>
      <Flex gap='16px'>
        <Select
          placeholder={t('search_by')}
          className='w-1/2 h-full min-w-[140px]'
          allowClear
          size='middle'
          value={searchValue.searchBy}
          onChange={handleSelectChange}
        >
          {searchItems?.map((search, index) => (
            <Option key={index} value={search?.value}>
              {search?.label}
            </Option>
          ))}
        </Select>
        <Input
          size='middle'
          placeholder={t('search')}
          prefix={<SearchIcon />}
          className='w-full rounded-lg'
          allowClear
          onChange={handleSearchChange}
          defaultValue={searchValue?.search || ''}
        />
      </Flex>
      <Button
        className='rounded-md border-solid border-customTunaPrimary'
        onClick={() => navigate(ROUTE.IMPORT)}
      >
        <Heading
          leadingIcon={<ImportIcon width={20} height={20} className='mt-1' />}
          title={t('import')}
          fontWeight='font-bold'
        />
      </Button>
      <Button
        className='rounded-md border-solid border-customTunaPrimary'
        onClick={() => setIsExportModalOpen(true)}
      >
        <Heading
          leadingIcon={<ExportIcon2 width={20} height={20} className='mt-1' />}
          title={t('export')}
          fontWeight='font-bold'
        />
      </Button>
      <Button onClick={() => navigate(ROUTE.ADDNEWORDER)} type='primary' className='rounded-md'>
        <AddIcon width={20} height={20} />
        <Heading title={t('new_order')} fontWeight='font-bold' />
      </Button>
      <ExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        onExport={handleExport}
      />
    </Flex>
  );
};

export default ButtonHeaderOrders;
