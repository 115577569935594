import { Card, TableAction } from '@/components';
import { useTranslation } from 'react-i18next';
import { StepProps } from './step2';
import { useEffect, useState } from 'react';
import userImportData from '@/hooks/useImportData';
import { Button, Flex } from 'antd';
import { ButtonFilter } from '@/types';

const pageSize = 25;

const Step3 = ({ id, setRow }: StepProps) => {
  const { t } = useTranslation();
  const [buttonFilter, setButtonFilter] = useState<ButtonFilter>(ButtonFilter.All);

  // pagination
  const [current, setCurrent] = useState(1);

  // get columns and data table
  const { columns, dataTables, loadingOrderExcel, total, invalid_rows_count } = userImportData(
    String(id),
    current,
    buttonFilter,
    setCurrent,
    pageSize,
  );

  useEffect(() => {
    setRow?.(invalid_rows_count);
  }, [invalid_rows_count, columns]);

  // handle onChange pagination
  const handlePaginationChange = (page: number) => {
    setCurrent(page);
  };

  // handle button filter
  const handleChangeButton = (buttonType: ButtonFilter) => {
    setButtonFilter(buttonType);
  };

  //handle change active button
  const activeButton = (buttonType: ButtonFilter) => {
    return buttonFilter === buttonType ? 'primary' : 'default';
  };

  return (
    <Flex vertical>
      <Card extraClassName='mb-6'>
        <Flex gap={10}>
          <Button
            type={activeButton(ButtonFilter.All)}
            onClick={() => handleChangeButton(ButtonFilter.All)}
          >
            {t('all')}
          </Button>
          <Button
            type={activeButton(ButtonFilter.VALID)}
            onClick={() => handleChangeButton(ButtonFilter.VALID)}
          >
            {t('valid')}
          </Button>
          <Button
            type={activeButton(ButtonFilter.INVALID)}
            onClick={() => handleChangeButton(ButtonFilter.INVALID)}
          >
            {t('invalid')}
          </Button>
        </Flex>
      </Card>
      {/* <Flex vertical className='mb-6 h-auto overflow-y-scroll'> //TODO */}
      <TableAction
        columns={columns}
        data={dataTables}
        pageSize={pageSize}
        current={current}
        onPaginationChange={handlePaginationChange}
        total={total}
        loading={loadingOrderExcel}
      />
      {/* </Flex> */}
    </Flex>
  );
};

export default Step3;
