import { fetchLabelRemittanceByUID } from '@/api';
import { DownloadIcon, Heading, PriceWithUnit, StatusTag } from '@/components';
import { ActionsTable } from '@/components/Action';
import {
  FINANCE_AMOUNT,
  FINANCE_DATE,
  ORDER_ACTIONS,
  ORDER_ID,
  ORDER_STATUS,
  REMITTANCE_ID,
  ROUTE,
  STYLE_PRICE,
  THEME_VARIABLE,
  TITLE,
  TRANSACTION_ID,
} from '@/config';
import { FinanceCOD, FinanceTab, FinanceTransaction } from '@/types';
import { convertedText, downloadFile } from '@/utils';
import { getStatusTagData } from '@/utils/getStatus';
import { Flex, MenuProps } from 'antd';
import { ColumnsType } from 'antd/es/table';

// Function to get the columns based on the active tab
const onClickDropdown = async (menuKey: any, uid: string, record: any) => {
  if (menuKey.key === 'download') {
    await downloadFile(uid, fetchLabelRemittanceByUID, `${record?.remittanceId}.pdf`);
  }
};

export const getColumns = (activeTab: FinanceTab, t: (key: string) => string) => {
  const menuItemsCOD: MenuProps['items'] = [
    {
      key: 'download',
      label: (
        <Heading title={t('download_statement')} leadingIcon={<DownloadIcon />} rotation={false} />
      ),
    },
  ];

  const financeCODColumns: ColumnsType<FinanceCOD> = [
    {
      title: t('date'),
      dataIndex: FINANCE_DATE,
      key: FINANCE_DATE,
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      render: (text) => <Heading title={text} />,
    },
    {
      title: t('remittance_id'),
      dataIndex: REMITTANCE_ID,
      key: REMITTANCE_ID,
      render: (text) => <Heading title={text} />,
    },
    {
      title: t('remittance_amount'),
      dataIndex: FINANCE_AMOUNT,
      key: FINANCE_AMOUNT,
      render: (text) => (
        <PriceWithUnit
          color={THEME_VARIABLE.customTunaPrimary}
          value={text}
          subTitle={t('iqd')}
          fontSize='14px'
          fontSizeSub='text-sm'
          fontWeight='font-normal'
          tagStyle={STYLE_PRICE}
          fontWeightSub='font-medium'
        />
      ),
    },
    // {
    //   title: t('ETA'),
    //   dataIndex: ETA,
    //   key: ETA,
    //   render: (text) => <Heading title={text} fontSize='text-sm' />,
    // },
    {
      title: t('status'),
      dataIndex: ORDER_STATUS,
      key: ORDER_STATUS,
      render: (text: string) => (
        <StatusTag
          text={t(text)}
          color={getStatusTagData(text)?.tagColor || 'processing'}
          iconColor={getStatusTagData(text)?.iconColor || ''}
        />
      ),
    },
    {
      title: t('actions'),
      key: ORDER_ACTIONS,
      render: (record) => (
        <ActionsTable
          buttonType='default'
          menuItems={menuItemsCOD}
          routeLink={`${ROUTE.FINANCE_DETAILS}/${record?.key}`}
          onClickDropDown={(menuKey) => onClickDropdown(menuKey, record.key, record)}
        />
      ),
      width: '5%',
      className: 'text-right',
      fixed: 'right' as 'right' | undefined,
    },
  ];

  const financeTransactionColumns: ColumnsType<FinanceTransaction> = [
    {
      title: t('date'),
      dataIndex: FINANCE_DATE,
      key: FINANCE_DATE,
      sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      render: (text) => <Heading title={text} />,
    },
    {
      title: t('order_id'),
      dataIndex: ORDER_ID,
      key: ORDER_ID,
      render: (text) => <Heading title={text} />,
    },
    {
      title: t('transaction_Id'),
      dataIndex: TRANSACTION_ID,
      key: TRANSACTION_ID,
      render: (text) => <Heading title={text} />,
    },
    {
      title: t('title'),
      dataIndex: TITLE,
      key: TITLE,
      render: (text) => <Heading title={convertedText(text || '')} fontSize='text-sm' />,
    },
    {
      title: t('amount'),
      dataIndex: FINANCE_AMOUNT,
      key: FINANCE_AMOUNT,
      render: (text, record) => {
        return (
          <Flex className='flex items-end gap-2'>
            {/* <Heading title={Number(record.amount) < 0 ? '-' : '+'} /> */}
            <PriceWithUnit
              color={
                Number(record.amount) < 0
                  ? THEME_VARIABLE.customValentineRed
                  : THEME_VARIABLE.customGreen
              }
              value={text}
              subTitle={t('iqd')}
              fontSize='14px'
              fontSizeSub='text-sm'
              fontWeight='font-normal'
              fontWeightSub='font-medium'
              colorSubTitle={
                Number(record.amount) < 0 ? 'text-customValentineRed' : 'text-customGreen'
              }
            />
          </Flex>
        );
      },
      fixed: 'right' as 'right' | undefined,
    },
  ];

  switch (activeTab) {
    case FinanceTab.COD:
      return financeCODColumns;

    case FinanceTab.Transactions:
      return financeTransactionColumns;

    default:
      return [];
  }
};
