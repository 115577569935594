import { OrderStatusTab } from '@/types';

export const ORDER_STATUE_GROUPED_BY_TAB: { [key in OrderStatusTab]: string[] } = {
  [OrderStatusTab.ReadyToShip]: ['new'],
  [OrderStatusTab.InProgress]: [
    'scheduled',
    'collecting',
    'out-for-collecting',
    'collected',
    'sorted',
    'transferred-carrier',
    'in-transit',
    'out-for-delivery',
    'postponed',
    'en-route', // ? is it correct?
    'out-for-collection', // ? is it correct?
    'received-warehouse',
    'received-carrier-warehouse',
  ],
  [OrderStatusTab.OnHold]: ['on-hold', 'hold-resolved'],
  [OrderStatusTab.RTO]: [
    'rto-received-warehouse',
    'rto-in-transit',
    'rto-requested',
    'rto-scheduled',
    'rto-received-carrier-warehouse',
    'rto-collecting',
  ],
  [OrderStatusTab.Finished]: [
    'delivered',
    'rto-partially-delivered',
    'rto-delivered',
    'partially-delivered',
    'cancelled',
  ],
};
