// sentryQueryWrapper.ts
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryKey,
} from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { handleErrorMessage } from './handleError';

type SentryQueryOptions<TData, TError> = UseQueryOptions<TData, TError> & {
  defaultErrorMessage?: string;
};

type SentryMutationOptions<TData, TError, TVariables> = UseMutationOptions<
  TData,
  TError,
  TVariables
> & {
  defaultErrorMessage?: string;
};

// Wrapper for `useQuery` with Sentry tracing in v8
export function useSentryQuery<TData, TError>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: SentryQueryOptions<TData, TError>,
) {
  const { defaultErrorMessage, ...queryOption } = options ?? {};

  return useQuery<TData, TError>(
    queryKey,
    async () => {
      return await Sentry.startSpan(
        {
          name: `Query: ${queryKey[0]}`, // Name for the span
          op: 'query',
          attributes: { queryKey: JSON.stringify(queryKey) },
        },
        async (span) => {
          try {
            const result = await queryFn();
            // span.setStatus('ok'); // Set status as 'ok' on success
            return result;
          } catch (error: any) {
            // span.setStatus('error'); // Set status as 'error' on failure
            Sentry.captureException(error);
            handleErrorMessage(
              { ...error?.response?.data, statusCode: error?.status },
              defaultErrorMessage,
            );

            throw error;
          }
        },
      );
    },
    queryOption,
  );
}

// Wrapper for `useMutation` with Sentry tracing in v8
export function useSentryMutation<TData, TError, TVariables>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: SentryMutationOptions<TData, TError, TVariables>,
) {
  const { defaultErrorMessage, ...queryOption } = options ?? {};

  return useMutation<TData, TError, TVariables>(async (variables: TVariables) => {
    return await Sentry.startSpan(
      {
        name: `Mutation: ${mutationFn.name || 'unnamed'}`, // Name for the span
        op: 'mutation',
      },
      async (span) => {
        try {
          const result = await mutationFn(variables);
          // span.setStatus('ok'); // Set status as 'ok' on success
          return result;
        } catch (error: any) {
          // span.setStatus('error'); // Set status as 'error' on failure
          Sentry.captureException(error);
          handleErrorMessage(
            { ...error?.response?.data, statusCode: error?.status },
            defaultErrorMessage,
            true,
          );

          throw error;
        }
      },
    );
  }, queryOption);
}
