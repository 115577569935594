import { SVGProps } from 'react';

interface BoxyLogoSmallProps extends SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

const BoxyLogoSmall = ({
  width = 18,
  height = 18,
  color = '#38383E',
  ...props
}: BoxyLogoSmallProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' {...props}>
    <path
      fill='#38383E'
      fillRule='evenodd'
      d='M15.51 17.69H2.503a2.187 2.187 0 0 1-2.188-2.187v-9.53c0-.825.465-1.58 1.203-1.953L8.015.745a2.188 2.188 0 0 1 1.968 0l6.51 3.275a2.187 2.187 0 0 1 1.204 1.954v9.53a2.187 2.187 0 0 1-2.188 2.187Zm-1.823-1.25h1.822a.937.937 0 0 0 .938-.937V5.974c0-.354-.2-.678-.516-.837L9.42 1.86a.938.938 0 0 0-.843 0L2.082 5.137a.937.937 0 0 0-.516.837v9.53a.937.937 0 0 0 .938.937h11.183Zm-.29-11.213v.001c.179.179.29.426.29.698v4.224c0 .45-.176.874-.495 1.193L9.264 15.27l-.001.001-.048.044-.014.012a.962.962 0 0 1-.05.04l-.005.004a.577.577 0 0 1-.048.033l-.016.01a.728.728 0 0 1-.051.03l-.01.006a1.129 1.129 0 0 1-.05.024l-.018.008a.934.934 0 0 1-.051.02l-.016.006-.047.015-.021.006-.05.012-.022.004a.95.95 0 0 1-.118.014l-.025.002h-.069a.644.644 0 0 1-.077-.006 1.175 1.175 0 0 1-.041-.006l-.027-.005a.81.81 0 0 1-.043-.01l-.034-.008-.04-.011-.027-.01a.671.671 0 0 1-.057-.02.972.972 0 0 1-.61-.912v-3.528H4.052a.972.972 0 0 1-.912-.61l-.007-.018-.014-.039-.01-.027-.011-.04-.009-.034a.736.736 0 0 1-.02-.111l-.003-.03-.002-.047a.71.71 0 0 1 0-.025l-.001-.013v-.03l.002-.026.004-.05.003-.023.007-.046a.762.762 0 0 1 .016-.071l.006-.021a.62.62 0 0 1 .02-.063l.021-.051.009-.02a1.01 1.01 0 0 1 .059-.109l.01-.016a1.094 1.094 0 0 1 .077-.103l.012-.014.044-.048h.001l3.929-3.93a1.676 1.676 0 0 1 1.193-.494h4.224c.272 0 .518.111.697.29Zm-5.145.697v4.447H12.7c.172 0 .312-.14.312-.313V5.924a.313.313 0 0 0-.312-.313H8.565a.313.313 0 0 0-.313.313Zm3.545 2.23c.161.043.258.21.215.37-.07.27-.255.51-.518.68a1.65 1.65 0 0 1-.882.248c-.647 0-1.217-.358-1.384-.87a.302.302 0 0 1 .194-.383.302.302 0 0 1 .383.194.597.597 0 0 0 .266.309c.152.092.336.143.541.143.205 0 .402-.054.555-.152a.574.574 0 0 0 .26-.323.302.302 0 0 1 .37-.216Zm-1.935-.5a.304.304 0 0 1-.304-.304v-.51a.304.304 0 0 1 .607 0v.51a.304.304 0 0 1-.303.304Zm1.514 0a.304.304 0 0 1-.303-.304v-.51a.304.304 0 0 1 .607 0v.51a.304.304 0 0 1-.304.304Z'
      clipRule='evenodd'
    />
  </svg>
);

export default BoxyLogoSmall;
