import { fetchLabelsPickupByUID } from '@/api';
import {
  BottomSheet,
  Card,
  CloseOutline,
  DownloadIcon,
  DriverInfo,
  Heading,
  MapComponent,
  OrderStatus,
  PaymentStatus,
  ProductDetails,
} from '@/components';
import { OrderItemStatus } from '@/components/PickupsCard/OrderStatus';
import { ROUTE } from '@/config';
import { useCancelPickupById, useFetchSinglePickupById } from '@/hooksApi';
import { downloadFile } from '@/utils';
import { getStatusActivePickup } from '@/utils/getStatus';
import { useQueryClient } from '@tanstack/react-query';
import { Col, Flex, Layout, MenuProps, message, Row, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const PickupSingle = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { mutate: CancelPickupById, isLoading: cancelPickupLoading } = useCancelPickupById();
  const [disabled, setDisabled] = useState(false);

  const { data: dataSinglePickup, isLoading: loadingSinglePickup } = useFetchSinglePickupById(
    id as string,
    600000,
  );

  const items: OrderItemStatus[] = [
    {
      title: t('scheduled'),
      // description: '12:23 PM',
    },
    {
      title: t('collect'),
      // description: '12:23 PM',
    },
    {
      title: t('collected'),
    },
  ];

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Heading
          title={t('cancel')}
          leadingIcon={<CloseOutline />}
          rotation={false}
          loading={cancelPickupLoading}
          onClick={() => {
            CancelPickupById(id as string, {
              onSuccess: () => {
                message.success(t('operation'));
                queryClient.refetchQueries(['pickups']);
              },
            });
          }}
        />
      ),
    },
    // {
    //   type: 'divider',
    // },
    // {
    //   key: '2',
    //   label: <Heading title={t('export_to_csv')} leadingIcon={<ExportIcon />} rotation={false} />,
    // },
  ];

  if (loadingSinglePickup) return <Spin />;

  return (
    <Layout className='relative h-fit'>
      {/* Pickup Title */}
      <Row justify='start' align='middle'>
        <Heading
          routeLink={ROUTE.PICKUPS}
          title={t('pickup_details')}
          subTitle={`#${dataSinglePickup?.object?.platform_code || ''}`}
          fontSize='text-2xl'
          subTitleClassName='font-normal text-2xl leading-[27.84px] text-customTunaPrimary'
          fontWeight='font-bold'
        />
      </Row>
      {/* End of Pickup Title */}

      <Row gutter={[24, 16]} className='mt-6'>
        {/* Map */}
        <Col md={14}>
          <Flex vertical className={`max-h-[28rem] rounded-[10px] shadow-card-dark`}>
            <MapComponent
              targetLocation={
                dataSinglePickup?.object.task
                  ? {
                      latitude: Number(dataSinglePickup?.object.task.lat),
                      longitude: Number(dataSinglePickup?.object.task.lng),
                    }
                  : undefined
              }
              driverLocation={
                dataSinglePickup?.object.driver?.last_position
                  ? {
                      latitude: Number(dataSinglePickup?.object.driver?.last_position.lat),
                      longitude: Number(dataSinglePickup?.object.driver?.last_position.lng),
                    }
                  : undefined
              }
            />
          </Flex>
        </Col>
        {/*End of Map */}

        {/* ORDERS STATUS And Payment */}
        <Col md={10}>
          <Flex vertical justify='space-between h-full flex flex-col'>
            <Card extraClassName='px-0 py-6 mb-5'>
              <OrderStatus
                statusMessage={t(dataSinglePickup?.object?.status?.title || 'new')}
                items={items}
                current={getStatusActivePickup(dataSinglePickup?.object?.status?.title || 'new')}
              />
            </Card>
            <Card>
              <PaymentStatus
                headerTitle={t('payment')}
                titleFirst={t('order_value')}
                titleSecond={t('shipment_fee')}
                total={Number(dataSinglePickup?.object?.total_price) || 0}
                valueFirst={Number(dataSinglePickup?.object?.order_value) || 0}
                valueSecond={Number(dataSinglePickup?.object?.shipment_fee) || 0}
              />
            </Card>
          </Flex>
        </Col>
        {/* End of ORDERS STATUS And Payment */}
      </Row>

      {/* DRIVER INFO And PRODUCT DETAILS */}
      <Row gutter={[24, 16]}>
        <Col md={14} xs={24} className='mb-16'>
          <Card>
            <DriverInfo
              assign={dataSinglePickup?.object.driver === null ? false : true}
              driver={dataSinglePickup?.object.driver}
            />
          </Card>
          <Card>
            <ProductDetails value={String(dataSinglePickup?.object?.orders_count)} />
          </Card>
        </Col>
      </Row>
      {/* End of DRIVER INFO And PRODUCT DETAILS */}

      {/* BottomSheet */}
      <BottomSheet
        isVisible={true}
        labelBtn={t('labels')}
        Icon={<DownloadIcon />}
        loadingSubmit={disabled}
        onSubmit={async () => {
          setDisabled(true);
          await downloadFile(id as string, fetchLabelsPickupByUID, 'pickup-labels.pdf');
          setDisabled(false);
        }}
        showRecords={false}
        menuItems={menuItems}
      />
      {/* End of BottomSheet */}
    </Layout>
  );
};

export default PickupSingle;
