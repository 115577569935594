import { useQueryClient } from '@tanstack/react-query';
import { Col, Flex, Form, message, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomOrderCard, Heading, PickupDropCard } from '@/components';
import {
  ProductDetailsCard,
  CustomerDetailsCard,
  PackageAndPaymentCard,
  DeliveryInstructionCard,
  OrderIdCard,
} from '@/components/OrderForm';
import { ROUTE } from '@/config';
import { useChangeProduct, useUpdateOrderPricePreview } from '@/hooks';
import { useCreateContact, useRequestPickup, useCreateOrder } from '@/hooksApi';
import useProductStore from '@/stores/productStore';
import { BasicProduct, PickUpParams } from '@/types';
import { attachCountryCodeToPhone } from '@/utils';

const AddNewOrder = () => {
  const [selectedProvinceCode, setSelectedProvinceCode] = useState<string | undefined>(undefined);
  const [addOrderLoading, setAddOrderLoading] = useState<'creating' | 'readyToShip' | undefined>(
    undefined,
  );

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: mutateAddNewOrder } = useCreateOrder();
  const { mutate: mutateAddNewContact } = useCreateContact();

  const { products } = useProductStore();

  const {
    handleFormUpdated,
    orderPrice,
    orderPriceLoading,
    customShipmentFee,
    setCustomShipmentFee,
    setPackageSize,
    transformValuesToOrder,
    checkOrderFormValidation,
    handleProductChanged,
  } = useUpdateOrderPricePreview(products, form);

  const { handleAddProduct, handleDeleteProduct, handleUpdateProduct, setProducts } =
    useChangeProduct(handleProductChanged);

  const handleSubmit = (ready_to_ship: boolean) => {
    form.submit();
    form
      .validateFields()
      .then((values) => onFinish(values, ready_to_ship)) // true means special submit
      .catch((errorInfo) => {
        message.error(t('please_fill_the_all'));
        console.error('Validation Failed:', errorInfo);
      });
  };

  console.log({ addOrderLoading });
  const onFinish = (values: Store, ready_to_ship: boolean) => {
    setAddOrderLoading(ready_to_ship ? 'readyToShip' : 'creating');
    const orderPayload = transformValuesToOrder(values, ready_to_ship);

    mutateAddNewOrder(orderPayload, {
      onSuccess: () => {
        message?.success(t('order_added_successfully'));

        setProducts([]);
        form.resetFields(); // Reset the form fields after a successful submission

        if (ready_to_ship) {
          navigate(ROUTE.PICKUPS);
        } else {
          navigate(ROUTE.ORDERS);
        }
        setAddOrderLoading(undefined);
      },
    });

    if (values.saveToAddress) {
      const primaryPhoneNumber = values?.phoneNumberPrimary
        ? attachCountryCodeToPhone(values?.phoneNumberPrimary, values?.countryCodePrimary)
        : undefined;

      const secondaryPhoneNumber = values?.secondaryPhoneNumber
        ? attachCountryCodeToPhone(values?.secondaryPhoneNumber, values?.countryCodeSecondary)
        : undefined;

      const contactPayload = {
        full_name: values.name || '',
        phone: primaryPhoneNumber ?? '',
        additional_phones: secondaryPhoneNumber ? [secondaryPhoneNumber] : [],
        address_text: values.address || '',
        type: 'some type', // Assuming a type for the contact, you may modify this as needed
        region_uid: values?.region_uid || '',
      };

      mutateAddNewContact(contactPayload, {
        onSuccess: () => {
          queryClient.refetchQueries(['contacts']);
          message?.success(t('contact_added'));
        },
      });
    }
  };

  const handleCreateProduct = (product: BasicProduct) => {
    const formValues = form.getFieldsValue();

    const newProductList = [...(formValues.products ?? []), product];

    if (checkOrderFormValidation({ ...formValues, products: newProductList }, true)) {
      handleAddProduct(product);
      return true;
    }
    return false;
  };

  const handleSetSelectedProvinceCode = (provinceCode?: string) => {
    form.setFieldsValue({ province: provinceCode, region_uid: undefined });
    form.setFieldValue('region_uid', undefined);
    setSelectedProvinceCode(provinceCode);
  };

  return (
    <Flex vertical>
      <Form
        form={form}
        initialValues={{
          packageSize: 'S',
          CustomerOrMerchant: 'BY_CUSTOMER',
          CodOrPrepaid: 'COLLECT_ON_DELIVERY',
        }}
        name='add-new-order'
        onValuesChange={handleFormUpdated}
      >
        <Heading
          title={t('add_new_order')}
          routeLink='/orders'
          fontSize='text-2xl'
          fontWeight='font-black'
          extraClassName='leading-[27.84px]'
        />

        <Row gutter={[24, 16]} className='mt-[40px] mb-3'>
          <Col lg={14} md={24} xs={24}>
            <CustomerDetailsCard
              form={form}
              //onRequiredFieldsUpdate//?
              setSelectedProvinceCode={handleSetSelectedProvinceCode}
              selectedProvinceCode={selectedProvinceCode}
            />

            <DeliveryInstructionCard
              title={t('delivery_instruction')}
              description={t('add-delivery-instruction')}
              placeholder={t('do_not_ring_door')}
            />

            <OrderIdCard
              title={t('order_id')}
              description={t('if_you_do_not')}
              placeholder={t('order_id_custom')}
            />
            <ProductDetailsCard
              title={t('product_details')}
              placeholders={{
                title: t('product_name'),
                quantity: t('qty'),
                price: t('price'),
              }}
              products={products}
              handleCreateProduct={handleCreateProduct}
              handleDeleteProduct={handleDeleteProduct}
              handleUpdateProduct={handleUpdateProduct}
            />
            <PickupDropCard
              form={form}
              previewOrderPrice={(formValue) => {
                handleFormUpdated(
                  { saved_pick_up_address_uid: formValue.saved_pick_up_address_uid },
                  formValue,
                );
              }}
              transformValuesToPayload={transformValuesToOrder}
              title={t('pickup_dropoff')}
              option1Label={t('boxy_collects')}
              option2Label={t('you_drop_off')}
              collectDescription={t('boxy_agent_will_collect')}
              dropoffDescription={t('you_drop_off_your_packages')}
            />
          </Col>
          <Col lg={10} md={24} xs={24}>
            <PackageAndPaymentCard
              form={form}
              onPackageSizeChange={setPackageSize}
              orderPrice={orderPrice}
              orderPriceLoading={orderPriceLoading}
              onCustomShipmentFeeChange={setCustomShipmentFee}
              customShipmentFee={customShipmentFee}
            />
          </Col>
        </Row>
        <Row className='sticky bottom-4'>
          <Col lg={24} md={24} xs={24}>
            <BottomOrderCard
              addOrderLoading={addOrderLoading}
              disable={!!orderPrice.orders_value}
              createOrderTitle={t('create_order')}
              readyToShipTitle={t('create_ready_ship')}
              handleSubmit={handleSubmit}
            />
          </Col>
        </Row>
      </Form>
    </Flex>
  );
};

export default AddNewOrder;
