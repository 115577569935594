import { THEME_VARIABLE } from './theme.config';
export const setup = {
  APP_NAME: 'merchant',
  VERSION: '1.0.0',
};

// export const API_URL = 'https://api-stg.tryboxy.dev/api';
export const API_URL = import.meta.env.VITE_API_URL || 'https://api-stg.tryboxy.dev/api';

export const API_VERSION = 'v1';

export const DEFAULT_LANGUAGE = 'en';
export const LOCAL_EN = 'en';
export const LOCAL_AR = 'ar';
export const DIRECTION_RTL = 'rtl';
export const DIRECTION_LTR = 'ltr';

export const LOGIN_VIEW = 'login';

export const DEFAULT_COUNTRY_CODE = '964';

export const In_TRANSIT = 'ml-4';
export const STYLE_ORDERS = 'flex space-x-4 my-4 mx-4';
export const STYLE_HEADER = 'p-[10px] rounded-full w-[45px] h-[45px] bg-colorWhite';
export const STYLE_PICKUP_TASK =
  'bg-customGrayShade1 rounded-[4px] text-customTunaPrimary border-solid border-1 border-customGrayShade4 w-fit my-1 flex items-center';
export const STYLE_STATUS =
  'rounded-full capitalize text-customSemiBlue border-solid border-1 border-customSemiBlue flex w-fit items-center gap-x-2 text-sm';
export const STYLE_PICKUP_STATUS = 'rounded-full flex w-fit items-center gap-x-2  text-sm';

// columns variables

// orders
export const ORDER_STATUS = 'status';
export const PICKUP_LOCATION = 'pickupLocation';
export const TOTAL_VALUE = 'totalValue';
export const QUANTITY = 'quantity';
export const CUSTOMER_NAME = 'customerName';
export const ORDER_ID = 'orderId';
export const ORDER_DATE = 'orderDate';
export const ORDER_ACTIONS = 'actions';
export const PRODUCTS = 'products';
export const CUSTOM_ID = 'custom_id';
export const ON_HOLD_REASON = 'merchant_action_required_text';
export const LM_COMMENT = 'carrier_message';

// pickups
export const PICKUP = 'Pickup';
export const LOCATION = 'location';
export const DRIVER = 'driver';
export const DRIVER_TASKS = 'driver_tasks';
export const ETA = 'eta';

// bulk import
export const IMPORT_LABEL = 'label';
export const IMPORT_FIRST_ROW = 'value';
export const COLUMN_IMPORT_VALID = 'validity';
export const COLUMN_CUSTOM_DETAILS = 'customerDetails';
export const COLUMN_CUSTOMER_ADDRESS = 'customerAddress';
export const COLUMN_PRODUCT_DETAILS = 'customerAddress';
export const COLUMN_PACKAGE_SIZE = 'packageSize';
export const COLUMN_ORDER_PAYMENT = 'orderPayment';
export const COLUMN_SHIPMENT = 'shipmentPayment';
// finance
export const FINANCE_DATE = 'date';
export const REMITTANCE_ID = 'remittanceId';
export const TRANSACTION_ID = 'transactionId';
export const FINANCE_AMOUNT = 'amount';
export const TITLE = 'title';

// end of columns variables

export const FORM_TYPE_REGISTER = 'Register';
export const FORM_TYPE_LOGIN = 'Login';

//OrderTracker Statuses
export const Statuses = {
  DELIVERED: 'delivered',
  ON_HOLD: 'onHold',
  IN_PROGRESS: 'inProgress',
  CREATED: 'created',
} as const;

export const ALLOW_FILE_CSV = [
  'application/vnd.ms-excel',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

// Create a type from the values of the Statuses object to use in OrderTracker Statuses
export type StatusesType = (typeof Statuses)[keyof typeof Statuses];

//STATUS STYLES FOR OrderInfoCard STATUSES
export const STATUS_COLORS = {
  DELIVERED: {
    color: THEME_VARIABLE.customGreen,
    backgroundColor: THEME_VARIABLE.customGreenShade1,
  },
  ON_HOLD: {
    color: THEME_VARIABLE.customOrange,
    backgroundColor: THEME_VARIABLE.customOrangeShade1,
  },
  IN_PROGRESS: {
    color: THEME_VARIABLE.customGreen,
    backgroundColor: THEME_VARIABLE.customGreenShade1,
  },
  CREATED: {
    color: THEME_VARIABLE.customGreen,
    backgroundColor: THEME_VARIABLE.customGreenShade1,
  },
};
export const StatusValue = {
  COLLECTING: 'Collecting',
  NEW: 'New',
};

export const STYLE_STAR_USER =
  'absolute left-0 -bottom-1 right-0 bg-colorWhite rounded-full px-1 w-full flex justify-center items-center h-4';

export const STYLE_ARROW_LEFT =
  'border-solid border-[1px] border-customGrayShade5 rounded-[10px] p-2 mr-3 cursor-pointer';

export const IMAGE_SAMPLE = 'https://example.com/profile.jpg';

export const STYLE_SHARE_TAG =
  'bg-customGrayShade3 w-fit px-2 rounded-full border-[1px] border-solid border-customGrayShade4';

export const STYLE_TEXT_COD = 'text-customGreen font-medium';

export const STYLE_PRICE =
  'bg-customGrayShade3 w-fit px-2 rounded-full border-[1px] border-solid border-customGrayShade4';

export const STYLE_TAG_SHARE = 'rounded-[10px] flex items-center justify-center w-fit gap-x-2 me-0';

export const COPY = 'copy-icon';

export const STYLE_SPACE =
  'w-full p-3 rounded-[10px] flex justify-between border-solid border-customGrayShade3 border-[1px] mt-4';
